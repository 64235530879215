import React from 'react';
import './_pages.scss';
import { Footer, Navbar } from '../components/_index';

const LandingPage = () => {
  return (
    <>
      <Navbar />
      <div className="div-01">
        <img className="tower-01" src="/assets/images/tower-1.png" alt="" />
        <img className="tower-02" src="/assets/images/tower-1.png" alt="" />
        <img className="tower-03" src="/assets/images/tower-1.png" alt="" />
        <div className="gradiant" />
        <div className="get-assured">
          <span>
            <h3>Get assured clicks for your business</h3>
            <h4>Otherwise get your money refunded</h4>
          </span>
          <div className="illustration">
            <img src="/assets/images/illustration-1.png" alt="illustration" />
          </div>
        </div>
      </div>
      <div className="div-02">
        <img
          className="div-02__tower-01"
          src="/assets/images/tower-1.png"
          alt=""
        />
        <img
          className="div-02__tower-02"
          src="/assets/images/tower-1.png"
          alt=""
        />
        <img
          className="div-02__tower-03"
          src="/assets/images/tower-1.png"
          alt=""
        />
        <img
          className="div-02__tower-04"
          src="/assets/images/tower-1.png"
          alt=""
        />
        <p>
          Don't leave your business to fate when you can get assured results
        </p>
      </div>
      <div className="div-03">
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
