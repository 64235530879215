import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#245FD3',
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: 360,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    position: 'absolute',
    width: 60,
    left: 39,
    top: 48,
    paddingRight: 0,
  },
  logoText: {
    color: '#F2F2F2',
    position: 'absolute',
    width: 107,
    height: 28,
    left: 104,
    top: 45,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '0.02em',
  },
  image: {
    width: '92%',
    height: '550px',
  },
}));

const AuthSidebar = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ position: 'relative' }}>
        <Link to="/">
          <img
            alt="logo"
            src="../assets/images/social2.png"
            className={classes.logo}
          />
          <span className={classes.logoText}>For Business</span>
        </Link>
      </div>
      <div
        style={{
          marginTop: 122,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          alt="auth-illustration"
          src="../assets/images/auth-sidebar.png"
          className={classes.image}
        />
      </div>
    </div>
  );
};

export default AuthSidebar;
