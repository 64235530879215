import React, { useState, useEffect } from "react";
import "./QuestionForm.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import ShortTextIcon from "@material-ui/icons/ShortText";
import SubjectIcon from "@material-ui/icons/Subject";
import BackupIcon from "@material-ui/icons/Backup";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AppsIcon from "@material-ui/icons/Apps";
import { BsTrash } from "react-icons/bs";
import { IconButton, Slider } from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { useStateValue } from "./StateProvider";
import { actionTypes } from "./reducer";
import { useParams } from "react-router";
import axios from "axios";



//marks for slider

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
];

function FormPage({
  questions,
  setQuestions,
  documentName,
  documentDescription,
}) {
  const dispatch = useStateValue();
  const [questionType, setType] = useState("radio");
  const [questionRequired, setRequired] = useState("true");
  const [timevalue, setTimeValue] = useState(null);
  let { id } = useParams() || "123";

  console.log(id);

  function changeType(e) {
    // dispatch({
    //   type:"CHANGE_TYPE",
    //   questionType:e.target.id
    // })
    setType(e.target.id);
  }

  useEffect(() => {
    setType(questionType);
  }, [changeType]);

  function saveQuestions() {
    console.log("auto saving questions initiated");
    var data = {
      formId: id,
      name: documentName,
      description: documentDescription,
      questions: questions,
    };
    setQuestions(questions);
  }

  function addMoreQuestionField() {
    expandCloseAll();

    setQuestions((questions) => [
      ...questions,
      {
        questionText: "Question",
        questionType: "radio",
        options: [{ optionText: "Option 1" }],
        open: true,
        required: false,
      },
    ]);
  }

  function addQuestionType(i, type) {
    let qs = [...questions];

    qs[i].questionType = type;
    console.log(type, "\n", qs);
    switch (type) {
      case "text":
        qs[i].options = [{ optionText: "word count" }];
        break;
      case "date":
        qs[i].options = [{ optionText: "" }];
        break;
      case "time":
        qs[i].options = [{ optionText: "" }];
        break;
      case "file":
        qs[i].options = [{ optionText: "" }];
        break;
      case "binary":
        qs[i].options = [{ optionText: "yes" }, { optionText: "no" }];
        break;
      case "linear":
        qs[i].options = [{ optionText: "Scale" }];
        break;
    }

    setQuestions(qs);
  }

  function copyQuestion(i) {
    expandCloseAll();
    let qs = [...questions];
    var newQuestion = qs[i];

    setQuestions([...questions, newQuestion]);
  }

  function deleteQuestion(i) {
    let qs = [...questions];
    if (questions.length > 1) {
      qs.splice(i, 1);
    }
    setQuestions(qs);
  }

  function handleOptionValue(text, i, j) {
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].options[j].optionText = text;
    //newMembersEmail[i]= email;
    setQuestions(optionsOfQuestion);
  }

  function handleQuestionValue(text, i) {
    var optionsOfQuestion = [...questions];
    optionsOfQuestion[i].questionText = text;
    setQuestions(optionsOfQuestion);
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    var itemgg = [...questions];
    const itemF = reorder(
      itemgg,
      result.source.index,
      result.destination.index
    );
    setQuestions(itemF);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function showAsQuestion(i) {
    let qs = [...questions];
    qs[i].open = false;
    setQuestions(qs);
  }

  function addOption(i) {
    var optionsOfQuestion = [...questions];
    if (optionsOfQuestion[i].options.length < 5) {
      optionsOfQuestion[i].options.push({
        optionText: "Option " + (optionsOfQuestion[i].options.length + 1),
      });
    } else {
      console.log("Max  5 options ");
    }
    //console.log(optionsOfQuestion);
    setQuestions(optionsOfQuestion);
  }

  function setOptionAnswer(ans, qno) {
    var Questions = [...questions];

    Questions[qno].answer = ans;

    setQuestions(Questions);
    console.log(qno + " " + ans);
  }

  function setOptionPoints(points, qno) {
    var Questions = [...questions];

    Questions[qno].points = points;

    setQuestions(Questions);
    console.log(qno + " " + points);
  }
  function addAnswer(i) {
    var answerOfQuestion = [...questions];

    answerOfQuestion[i].answer = !answerOfQuestion[i].answer;

    setQuestions(answerOfQuestion);
  }

  function doneAnswer(i) {
    var answerOfQuestion = [...questions];

    answerOfQuestion[i].answer = !answerOfQuestion[i].answer;

    setQuestions(answerOfQuestion);
  }

  function requiredQuestion(i) {
    var requiredQuestion = [...questions];

    requiredQuestion[i].required = !requiredQuestion[i].required;

    console.log(requiredQuestion[i].required + " " + i);
    setQuestions(requiredQuestion);
  }

  function removeOption(i, j) {
    var optionsOfQuestion = [...questions];
    if (optionsOfQuestion[i].options.length > 1) {
      optionsOfQuestion[i].options.splice(j, 1);
      setQuestions(optionsOfQuestion);
      console.log(i + "__" + j);
    }
  }

  function expandCloseAll() {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      qs[j].open = false;
    }
    setQuestions(qs);
  }

  function handleExpand(i) {
    let qs = [...questions];
    for (let j = 0; j < qs.length; j++) {
      if (i === j) {
        qs[i].open = true;
      } else {
        qs[j].open = false;
      }
    }
    setQuestions(qs);
  }

  function optionEnter(type, i, j, optionText) {
    {
      switch (type) {
        case "radio":
          return (
            <input
              type="text"
              className="text_input"
              placeholder="option"
              value={optionText}
              onChange={(e) => {
                handleOptionValue(e.target.value, i, j);
              }}
            ></input>
          );
          break;
        case "checkbox":
          return (
            <input
              type="text"
              className="text_input"
              placeholder="option"
              value={optionText}
              onChange={(e) => {
                handleOptionValue(e.target.value, i, j);
              }}
            ></input>
          );
          break;
        case "text":
          return (
            <>
              <input
                id="outlined-basic"
                label="Word Limit"
                disabled
                className="text_input"
                // style={{ transform: "scale(1)",flex:"auto",margin:"10px 15px",height:"40px !important"}}
                type="number"
                placeholder="word limit : 250"
                variant="outlined"
              />
            </>
          );
          break;
        case "date":
          return (
            <input
              type="date"
              className="text_input"
              placeholder="option"
              value={new Date("2018-01-01T00:00:00.000Z")}
              style={{
                border: "1px solid #336CFB66",
                padding: "5px 10px",
                marginRight: "10px",
              }}
            />
          );

        case "time":
          return (
            <input
              type="time"
              className="text_input"
              style={{ border: "1px solid #336CFB66", padding: "5px 10px" }}
              placeholder="option"
              value="22:00:00"
            />
          );
        case "file":
          return (
            <>
              <Typography
                style={{
                  flex: "auto",
                  fontFamily: "Roboto,Arial,sans-serif",
                  fontSize: "13px",
                  fontWeight: "400",
                  letterSpacing: ".2px",

                  color: "#202124",
                }}
              >
                Upload File
              </Typography>
            </>
          );
          break;
        case "scale":
          return <></>;

        case "binary":
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
             {j==0 ? <> <CheckIcon />
              <Typography
                style={{
                  flex: "auto",
                  fontFamily: "Roboto,Arial,sans-serif",
                  fontSize: "13px",
                  fontWeight: "400",
                  letterSpacing: ".2px",
                  marginLeft: "10px",
                  color: "#202124",
                }}
              >
                Yes 
              </Typography>
              </>:<>
              <ClearIcon />
              <Typography
                style={{
                  flex: "auto",
                  fontFamily: "Roboto,Arial,sans-serif",
                  fontSize: "13px",
                  fontWeight: "400",
                  letterSpacing: ".2px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  color: "#202124",
                }}
              >
                No
              </Typography>
              </>}
            </div>
          );
        default:
          return (
            <input
              type="date"
              className="text_input"
              placeholder="option"
              value={optionText}
              onChange={(e) => {
                handleOptionValue(e.target.value, i, j);
              }}
            ></input>
          );
      }
    }
  }

  function optionBox(type) {
    switch (type) {
      case "checkbox":
        return <input disabled type={type} style={{ marginRight: "10px" }} />;
        break;
      case "date":
        return <></>;
        break;
      case "time":
        return <></>;
        break;
      case "radio":
        return <input disabled type={type} style={{ marginRight: "10px" }} />;
        break;
      case "file":
        return <BackupIcon style={{ marginRight: "10px" }} />;
      case "scale":
        return (
          <Slider
            style={{ marginRight: "10px" }}
            defaultValue={3}
            step={1}
            marks={marks}
            min={1}
            max={9}
            disabled
          />
        );
        break;
      case "binary":
        return <></>;
        break;
      default:
        return <ShortTextIcon style={{ marginRight: "10px" }} />;
        break;
    }
  }

  function questionOptionBox(type, required) {
    switch (type) {
      case "checkbox":
        return (
          <input
            type={type}
            color="primary"
            style={{ marginRight: "3px" }}
            required={required}
          />
        );
        break;
      case "date":
        return (
          <>
            <EventIcon style={{ marginRight: "10px" }} />
            <Typography
              style={{
                flex: "auto",
                fontFamily: "Roboto,Arial,sans-serif",
                fontSize: "13px",
                fontWeight: "400",
                letterSpacing: ".2px",
                marginRight: "10px",
                color: "#202124",
              }}
            >
              Date
            </Typography>
          </>
        );
        break;
      case "time":
        return (
          <>
            <ScheduleIcon style={{ marginRight: "10px" }} />
            <Typography
              style={{
                flex: "auto",
                fontFamily: "Roboto,Arial,sans-serif",
                fontSize: "13px",
                fontWeight: "400",
                letterSpacing: ".2px",
                marginRight: "10px",
                color: "#202124",
              }}
            >
              Time
            </Typography>
          </>
        );
        break;
      case "radio":
        return (
          <input
            type={type}
            color="primary"
            style={{ marginRight: "3px" }}
            required={required}
          />
        );
        break;
      case "file":
        return (
          <>
            {" "}
            <BackupIcon style={{ marginRight: "10px" }} />
            <Typography
              style={{
                flex: "auto",
                fontFamily: "Roboto,Arial,sans-serif",
                fontSize: "13px",
                fontWeight: "400",
                letterSpacing: ".2px",
                marginRight: "10px",
                color: "#202124",
              }}
            >
              File Upload
            </Typography>
          </>
        );
      case "scale":
        return (
          <>
            <LinearScaleIcon style={{ marginRight: "10px" }} />
            <Typography
              style={{
                flex: "auto",
                fontFamily: "Roboto,Arial,sans-serif",
                fontSize: "13px",
                fontWeight: "400",
                letterSpacing: ".2px",
                marginRight: "10px",
                color: "#202124",
              }}
            >
              Scale
            </Typography>
          </>
        );
        break;
      case "binary":
        return (
          <>
            <CheckIcon style={{ margin: "0px", padding: "0px" }} />
            <ClearIcon style={{ margin: "0", padding: "0px" }} />
            <Typography
              style={{
                flex: "auto",
                fontFamily: "Roboto,Arial,sans-serif",
                fontSize: "13px",
                fontWeight: "400",
                letterSpacing: ".2px",
                marginRight: "10px",
                color: "#202124",
              }}
            >
              Binary
            </Typography>
          </>
        );

      default:
        return <ShortTextIcon style={{ marginRight: "10px" }} />;
        break;
    }
  }
  function questionsUI() {
    return questions?.map((ques, i) => (
      <Draggable key={i} draggableId={i + "id"} index={i}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div>
              <div style={{ marginBottom: "0px" }}>
                <div style={{ width: "100%", marginBottom: "0px" }}>
                  <DragIndicatorIcon
                    style={{
                      transform: "rotate(-90deg)",
                      color: "#DAE0E2",
                      position: "relative",
                      left: "300px",
                    }}
                    fontSize="small"
                  />
                </div>

                <Accordion
                  onChange={() => {
                    handleExpand(i);
                  }}
                  expanded={questions[i].open}
                  className={questions[i].open ? "add_border" : ""}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    elevation={1}
                    style={{ width: "100%", minHeight: "0" }}
                  >
                    {!questions[i].open ? (
                      <div className="saved_questions">
                        <Typography
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            letterSpacing: ".1px",
                            lineHeight: "24px",
                            paddingBottom: "8px",
                          }}
                        >
                          {i + 1}. {ques.questionText}
                        </Typography>

                        {ques.options.map((op, j) => (
                          <div key={j}>
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                style={{
                                  marginLeft: "5px",
                                  marginBottom: "5px",
                                }}
                                disabled
                                control={questionOptionBox(
                                  ques.questionType,
                                  ques.type
                                )}
                                label={
                                  <Typography
                                    style={{
                                      fontFamily: "Roboto,Arial,sans-serif",
                                      fontSize: " 13px",
                                      fontWeight: "400",
                                      letterSpacing: ".2px",
                                      lineHeight: "20px",
                                      color: "#202124",
                                    }}
                                  >
                                    {ques.options[j]?.optionText}
                                  </Typography>
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}
                  </AccordionSummary>
                  <div className="question_boxes">
                    {/* {!ques.answer ? ( */}
                    <AccordionDetails className="add_question">
                      <div>
                        <div className="add_question_top">
                          <input
                            type="text"
                            className="question"
                            placeholder="Question"
                            value={ques.questionText}
                            onChange={(e) => {
                              handleQuestionValue(e.target.value, i);
                            }}
                          ></input>
                          {/* <CropOriginalIcon style={{ color: "#5f6368" }} /> */}

                          <Select
                            className="select"
                            style={{ color: "#5f6368", fontSize: "13px" }}
                          >
                            {/* <MenuItem value="radio" className="menuitem" >
                                     <ShortTextIcon style={{marginRight:"10px"}} /> <span style={{marginBottom:"10px"}}>Short Paragraph</span></MenuItem>
                                     */}
                            <MenuItem
                              id="text"
                              value="Text"
                              onClick={() => {
                                addQuestionType(i, "text");
                              }}
                            >
                              {" "}
                              <SubjectIcon
                                style={{ marginRight: "10px" }}
                              />{" "}
                              Text
                            </MenuItem>

                            <MenuItem
                              id="radio"
                              value="Radio"
                              onClick={() => {
                                addQuestionType(i, "radio");
                              }}
                            >
                              <RadioButtonCheckedIcon
                                checked
                                style={{
                                  marginRight: "10px",
                                  color: "#70757a",
                                }}
                              />{" "}
                              Multiple Choice
                            </MenuItem>
                            <MenuItem
                              id="checkbox"
                              value="Checkbox"
                              onClick={() => {
                                addQuestionType(i, "checkbox");
                              }}
                            >
                              <CheckBoxIcon
                                style={{
                                  marginRight: "10px",
                                  color: "#70757a",
                                }}
                                checked
                              />{" "}
                              Checkboxes
                            </MenuItem>

                            <MenuItem
                              value="40"
                              onClick={() => {
                                addQuestionType(i, "file");
                              }}
                            >
                              {" "}
                              <BackupIcon
                                style={{ marginRight: "10px" }}
                              />{" "}
                              File Upload
                            </MenuItem>
                            <MenuItem
                              value="50"
                              onClick={() => {
                                addQuestionType(i, "scale");
                              }}
                            >
                              {" "}
                              <LinearScaleIcon
                                style={{ marginRight: "10px" }}
                              />{" "}
                              Linear Scale
                            </MenuItem>
                            {/* <MenuItem value="60">
                              {" "}
                              <AppsIcon style={{ marginRight: "10px" }} />{" "}
                              Tick-box grid
                            </MenuItem> */}

                            <MenuItem
                              value="aate"
                              onClick={() => {
                                addQuestionType(i, "date");
                              }}
                            >
                              {" "}
                              <EventIcon style={{ marginRight: "10px" }} /> Date
                            </MenuItem>
                            <MenuItem
                              value="date"
                              onClick={() => {
                                addQuestionType(i, "time");
                              }}
                            >
                              {" "}
                              <ScheduleIcon
                                style={{ marginRight: "10px" }}
                              />{" "}
                              Time
                            </MenuItem>
                            <MenuItem
                              value="binary"
                              onClick={() => {
                                addQuestionType(i, "binary");
                              }}
                            >
                              {" "}
                              <CheckIcon
                                style={{ margin: "0px", padding: "0px" }}
                              />
                              <ClearIcon
                                style={{ margin: "0", padding: "0px" }}
                              />{" "}
                              Binary
                            </MenuItem>
                          </Select>
                        </div>

                        {ques.options.map((op, j) => (
                          <div className="add_question_body" key={j}>
                            {/* <Checkbox  color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} disabled/> */}
                            {optionBox(ques.questionType)}

                            <div>
                              {optionEnter(
                                ques.questionType,
                                i,
                                j,
                                ques.options[j].optionText
                              )}
                            </div>

                            {/* <CropOriginalIcon style={{ color: "#5f6368" }} /> */}
                            { (ques.questionType === "checkbox" ||
                                    ques.questionType === "radio") && ques.options.length > 1 && 
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                removeOption(i, j);
                              }}
                            >
                              <BsTrash size="14px" />
                            </IconButton>}
                          </div>
                        ))}

                        {ques.options.length < 5 ? (
                          <div className="add_question_body">
                            <FormControlLabel
                              disabled
                              control={
                                <></>
                                // ques.questionType != "text" ? (
                                //   <input
                                //     type={ques.questionType}
                                //     color="primary"
                                //     inputProps={{
                                //       "aria-label": "secondary checkbox",
                                //     }}
                                //     style={{
                                //       marginLeft: "10px",
                                //       marginRight: "10px",
                                //     }}
                                //     disabled
                                //   />
                                // ) : (
                                //   <ShortTextIcon
                                //     style={{ marginRight: "10px" }}
                                //   />
                                // )
                              }
                              label={
                                <div>
                                  {/* <input
                                    type="text"
                                    className="text_input"
                                    style={{
                                      fontSize: "13px",
                                      width: "60px",
                                    }}
                                    placeholder="Add other"
                                  ></input> */}
                                  {(ques.questionType === "checkbox" ||
                                    ques.questionType === "radio") && (
                                    <Button
                                      size="small"
                                      onClick={() => {
                                        addOption(i);
                                      }}
                                      style={{
                                        textTransform: "none",
                                        color: "#4285f4",
                                        fontSize: "13px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <AddCircleOutlineIcon
                                        style={{ marginRight: "10px" }}
                                      />{" "}
                                      {ques.options.length}/5
                                    </Button>
                                  )}
                                </div>
                              }
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="add_footer">
                          {/* <div className="add_question_bottom_left">
                            <Button
                              size="small"
                              onClick={() => {
                                addAnswer(i);
                              }}
                              style={{
                                textTransform: "none",
                                color: "#4285f4",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              <FcRightUp
                                style={{
                                  border: "2px solid #4285f4",
                                  padding: "2px",
                                  marginRight: "8px",
                                }}
                              />{" "}
                              Answer key
                            </Button>
                          </div> */}

                          <div className="add_question_bottom">
                            <IconButton
                              aria-label="Copy"
                              onClick={() => {
                                copyQuestion(i);
                              }}
                            >
                              <FilterNoneIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                deleteQuestion(i);
                              }}
                            >
                              <BsTrash />
                            </IconButton>
                            <span
                              style={{ color: "#5f6368", fontSize: "13px" }}
                            >
                              Required{" "}
                            </span>{" "}
                            <Switch
                              name="checkedA"
                              color="primary"
                              checked={ques.required}
                              onClick={() => {
                                requiredQuestion(i);
                              }}
                            />
                            <IconButton onClick={addMoreQuestionField}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                    {/* ) : (
                      <AccordionDetails className="add_question">
                        <div className="top_header">Choose Correct Answer</div>
                        <div>
                          <div className="add_question_top">
                            <input
                              type="text"
                              className="question "
                              placeholder="Question"
                              value={ques.questionText}
                              onChange={(e) => {
                                handleQuestionValue(e.target.value, i);
                              }}
                              disabled
                            />
                            <input
                              type="number"
                              className="points"
                              min="0"
                              step="1"
                              placeholder="0"
                              onChange={(e) => {
                                setOptionPoints(e.target.value, i);
                              }}
                            />
                          </div>

                          {ques.options.map((op, j) => (
                            <div
                              className="add_question_body"
                              key={j}
                              style={{
                                marginLeft: "8px",
                                marginBottom: "10px",
                                marginTop: "5px",
                              }}
                            >
                              <div key={j}>
                                <div style={{ display: "flex" }} className="">
                                  <div className="form-check">
                                    <label
                                      style={{ fontSize: "13px" }}
                                      onClick={() => {
                                        setOptionAnswer(
                                          ques.options[j].optionText,
                                          i
                                        );
                                      }}
                                    >
                                      {ques.questionType != "text" ? (
                                        <input
                                          type={ques.questionType}
                                          name={ques.questionText}
                                          value="option3"
                                          className="form-check-input"
                                          required={ques.required}
                                          style={{
                                            marginRight: "10px",
                                            marginBottom: "10px",
                                            marginTop: "5px",
                                          }}
                                        />
                                      ) : (
                                        <ShortTextIcon
                                          style={{ marginRight: "10px" }}
                                        />
                                      )}

                                      {ques.options[j].optionText}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="add_question_body">
                            <Button
                              size="small"
                              style={{
                                textTransform: "none",
                                color: "#4285f4",
                                fontSize: "13px",
                                fontWeight: "600",
                              }}
                            >
                              {" "}
                              <BsFileText
                                style={{ fontSize: "20px", marginRight: "8px" }}
                              />
                              Add Answer Feedback
                            </Button>
                          </div>

                          <div className="add_question_bottom">
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                textTransform: "none",
                                color: "#4285f4",
                                fontSize: "12px",
                                marginTop: "12px",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                doneAnswer(i);
                              }}
                            >
                              Done
                            </Button>
                          </div>
                        </div>
                      </AccordionDetails>
                    )} */}
                    {/* {!ques.answer ? (
                      <div className="question_edit">
                        <AddCircleOutlineIcon
                          onClick={addMoreQuestionField}
                          className="edit"
                        />
                        <OndemandVideoIcon className="edit" />
                        <CropOriginalIcon className="edit" />
                        <TextFieldsIcon className="edit" />
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    ));
  }

  return (
    <>
      <div>
        <div className="question_form">
          <br></br>
          <div className="section">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questionsUI()}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* <div className="save_form">
              <Button
                variant="contained"
                color="primary"
                
                style={{ fontSize: "14px" }}
              >
                Save
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPage;
