import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  IconButton,
  Pagination,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { Button} from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArticleIcon from "@mui/icons-material/Article";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ebe9e9",
    color: "#25282B",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4FF",

  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


// const GetConversionandStatus=({id})=>{
//   const [budgetLeft,setBudgetLeft] = useState(0)
//   const [conversion,setConversion] = useState(0)

//   useEffect(() =>{
//     axios
//     .get(`https://socialchat.azurewebsites.net/earn/status/${id}`)
//       .then((result) => 
//       {
//        console.log(result.status === 200 , result?.data , result?.data?.count)
//         if(result.status === 200)
//         {
//           setBudgetLeft((result?.data?.count )*10)
//           setConversion((result?.data?.click))
//         }        
//         })
//       .catch((e) => console.log(e));
//   },[])
//   return <>
//   <StyledTableCell align="center">
//               {/* {row.budgetleft} */}
//               {budgetLeft ? budgetLeft: '---'}
              
//             </StyledTableCell>
//             <StyledTableCell align="center">
              
//               {conversion? conversion : '---'}
//             </StyledTableCell>
// </>
   
// }
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLeftIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const CustomTable = ({
  completed,
  total,
  setSelectedId,
  setTotal,
  setHandleModal,
  setHandleCompletedModal,
  tableData,
  setTypeSelected,
  fetching,
  setTableData,
  heading,
  setHandleMailModal,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows,setEmptyRows] = React.useState(0)
  const history = useHistory();
  useState(()=>{console.log('length',Math.max(0, (1 + page) * rowsPerPage - tableData.length) );
  setEmptyRows(Math.max(0, (1 + page) * rowsPerPage - tableData.length))},
  [rowsPerPage, setRowsPerPage,tableData])
    

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    axios.defaults.headers["x-auth-token"] = window.localStorage.getItem("jwt");
    console.log('tableData',tableData);
  }, []);

  const statusComponent = (status) => {
    switch (status) {
      case "Pending":
        return (
          <h2
            className="action"
            style={{
              color: "#FF6760",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="/assets/images/pending.png"
              alt="pending"
            />
            Pending
          </h2>
        );
      case "Modification":
        return (
          <h2
            className="action"
            style={{
              color: "#185ADB",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="/assets/images/request.png"
              alt="pending"
            />
            Request for <br />
            modification
          </h2>
        );
      case "Active":
        return (
          <h2
            className="action"
            style={{
              color: "#29A915",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="/assets/images/active.png"
              alt="active"
            />
            Active
          </h2>
        );
      case "Completed":
        return (
          <h2
            className="action"
            style={{
              color: "#336CFB",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="/assets/images/completed.png"
              alt="completed"
            />
            Completed
          </h2>
        );
      case "Rejected":
        return (
          <h2
            className="action"
            style={{
              color: "#FF6760",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="/assets/images/rejected.png"
              alt="rejected"
            />
            Rejected
          </h2>
        );
    }
  };

  const GetConversionandStatus=({id,status,budget})=>{
    const [budgetLeft,setBudgetLeft] = useState(0)
    const [conversion,setConversion] = useState(0)
  
    useEffect(() =>{
      axios
      .get(`https://socialchat.azurewebsites.net/earn/status/${id}`)
        .then((result) => 
        {
         console.log(result.status === 200 , result?.data , result?.data?.count)
          if(result.status === 200)
          {
            setBudgetLeft((result?.data?.count )*10)
            setConversion((result?.data?.click))
          }        
          })
        .catch((e) => console.log(e));
    },[])
    return <>
    <StyledTableCell align="center">
                
                {conversion? conversion : '---'}
              </StyledTableCell>
     <StyledTableCell align="center">
                {/* {row.budgetleft} */}
                {status==="Completed" &&  !isNaN(budget/conversion) ? (budget/conversion).toFixed(2) : '---'}
                
              </StyledTableCell>
              
  </>
     
  }
  return (
    <>
    <TableContainer
      component={Paper}
      style={{ top: "30px", minHeight: "430px" }}
    >
      <Table
        sx={{ minWidth: 700 , minHeight: "520px"}}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow style={{ height: "56px" }}>
          {heading.map((item,index)=> {
            return <StyledTableCell align="center" onClick={()=>{console.log(item)}}>{item}</StyledTableCell>
          })}
            
          </TableRow>
        </TableHead>

        {fetching ? (
          <TableBody>
            <StyledTableRow style={{ height: "100%" }}>
              <TableCell align="center" colSpan={9}>
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ marginRight: "20px" }} />
                  Loading...
                </h2>
              </TableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            {tableData.length > 0 ? (
              <TableBody>
                {(rowsPerPage > 0
                  ? tableData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : tableData
                ).map((row, index) => {
                  
                  {/* .get(`https://socialchat.azurewebsites.net/earn/status/6171214d9b890fd9c30de980`) */}


                  return  <StyledTableRow style={{height: '72px'}} key={row._id}>
                    <StyledTableCell component="th" scope="row">
                    <Tooltip title={row?.title ? row.title : ""}>
                      <span style={{cursor: "pointer" }}>{(row?.id).substring(0,2)+"-"+(row?.id).substring(2,10)}</span>
                    </Tooltip>
                    
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Rs. {row.budget}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                     ---
                    </StyledTableCell>
                    <StyledTableCell align="center">
                    {statusComponent(row?.status)}
                    </StyledTableCell>
                    <GetConversionandStatus id = {row?.id} status = {row?.status} budget={row?.budget} />
                    
                    <StyledTableCell align="center">
                    --- 
                    </StyledTableCell>
                    <StyledTableCell align="center">
                    --- 
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row?.status === "Completed" &&  <Button
                            onClick={() => {
                              setHandleModal(true);
                              setSelectedId(row._id);
                              row?.link
                                ? setTypeSelected("promo")
                                : setTypeSelected("survey");
                            }}
                            size="small"
                            variant="contained"
                            color="primary"
                          >
                            FeedBack
                          </Button>}
                    </StyledTableCell>
                  </StyledTableRow>
                  
                })}
               
                {Math.max(0, (1 + page) * rowsPerPage - tableData.length) > 0 && (
                  <TableRow style={{ height: `${72 * Math.max(0, (1 + page) * rowsPerPage - tableData.length)}px` }}>
                    <TableCell  colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow style={{ height: "100%" }}>
                  <TableCell align="center" colSpan={9}>
                    <h2
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ErrorIcon style={{ marginRight: "10px" }} />
                      No Users Found
                    </h2>
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </>
        )}

        
        <TableFooter style={{ width: "100% !important" }}>
        <Divider  style={{position: "absolute !important"}} />
          <TableRow style={{ height: "64px" ,width: "100% !important" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={7}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <Divider  style={{position: "absolute !important"}} />
        </TableFooter>
      </Table>
    </TableContainer>
    </>
  );
 };

export default CustomTable;
