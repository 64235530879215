const pattern = {
  phone: '^[0-9]{10}',
  email: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$',
};

export const validateEmail = (email) => {
  if (email.length === 0) return true;
  var patt = new RegExp(pattern.email);
  var matched = patt.test(email);
  if (matched) return true;
  if (email.length === 0) return true;
};

export const validatePhone = (phone) => {
  if (phone.length === 0) return true;
  if (phone.length > 10) return false;
  if (phone.length < 10) return false;
  var patt = new RegExp(pattern.phone);
  var matched = patt.test(phone);
  if (matched) return true;
};

export const validateUserId = (userId) => {
  if (userId.length === 0) return true;
  if (userId.length < 3) return false;
  return true;
};