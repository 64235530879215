import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Link, useHistory, Redirect } from 'react-router-dom';
import AuthSidebar from './AuthSidebar';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import {
  AuthContainer,
  AuthWrapper,
  Error,
  ModalContent,
  SignInBtn,
  SignupContainer,
  StyledTextInput,
  Title,
} from './styles';
import axios from 'axios';
import Modal from '../../helpers/Modal';
import { validateEmail, validatePhone } from '../../helpers/validations';

const api = process.env.REACT_APP_API;

const Signup = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [identity, setIdentity] = useState('');
  const [orgName, setOrgName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [referralId, setRefId] = useState('NOREF');
  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [verifyModal, setVerifyModal] = useState(false);
  const [panel, setPanel] = useState(1);

  const [userId, setUserId] = useState('');
  const [phoneToken, setPhoneToken] = useState('');
  const [emailToken, setEmailToken] = useState('');
  const [enteredOtp, setEnteredOtp] = useState('');

  const [verificationError, setVerificationError] = useState('');

  const handlePhone = (e) => {
    if (isNaN(e.target.value)) return;
    setPhone(e.target.value);
  };

  const handleCheck = (event) => {
    setAgreed(event.target.checked);
  };

  const handleDisable =
    name.length === 0 ||
    identity === '' ||
    (identity === 'Organization' && orgName === '') ||
    email.length === 0 ||
    password.length === 0 ||
    passwordConfirm !== password ||
    validateEmail(email) !== true ||
    validatePhone(phone) !== true ||
    agreed !== true;

  const signup = async () => {
    setLoading(true);
    const data = {
      name: name,
      identity: identity,
      company: identity === 'Organization' ? orgName : null,
      email,
      phone,
      password,
    };

    try {
      const res = await axios.post(`${api}/socialusers/register`, data);
      console.log(res);
      if (res.status === 200) {
        localStorage.setItem('userId', res.data.entity.id);
        setUserId(localStorage.getItem('userId'));
        sendVerification(res.data.entity.id);
      }
    } catch (error) {
      console.log(error.response);
      setError(error.response.data.message);
      setLoading(false);
    }
  };

  const sendVerification = async (id) => {
    const res = await axios.post(`${api}/socialusers/sendVerify`, { id });
    console.log('after verification', res);
    try {
      if (res.status === 200) {
        getUserInfo(id);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const getUserInfo = async (id) => {
    setLoading(true);
    try {
      const res = await axios.get(`${api}/socialusers/${id}`);
      console.log('user Info', res);
      console.log('user Info status', res.status);
      setPhoneToken(res.data.phoneToken);
      localStorage.setItem('etoken', res.data.emailToken);
      setEmailToken(localStorage.getItem('etoken'));
      setVerifyModal(true);
      setPanel(1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.response);
      setError(error.response.data.message);
    }
  };

  const updateVerification = async (phone, email) => {
    try {
      if (enteredOtp === phoneToken) {
        setVerificationError('');

        if (phone) {
          const data = {
            phoneVerified: true,
            token: phoneToken
          };
          const res = await axios.put(`${api}/socialusers/${userId}`, data);
          console.log('phone verified', res);
          setPanel(2);
        }

        if (email) {
          const id = localStorage.getItem('userId');
          const data = {
            emailVerified: true,
            verified: true,
            token: localStorage.getItem('etoken')
          };
          const res = await axios.put(`${api}/socialusers/${id}`, data);
          console.log('email verified', res);
        }
      } else {
        setVerificationError('Incorrect OTP');
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  useEffect(() => {
    let params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    if (params.token === localStorage.getItem('etoken')) {
      setVerifyModal(true);
      setPanel(3);
      updateVerification(null, 'email');
    }
  }, [window.location.search]);
  return (
    <>
      <AuthWrapper>
        <AuthSidebar />
        <AuthContainer>
          <div className="upper-helper-text">
            Already a member ?
            <Link to="/signin">
              <span> &nbsp; Sign In </span>
            </Link>
          </div>
          <SignupContainer>
            <h1>Sign Up to Social</h1>
            <div style={{ width: '380px' }}>
              {error && (
                <Error>
                  <InfoOutlinedIcon /> {error}
                </Error>
              )}
            </div>
            <div className="name-org">
              <div className="name">
                <Title>Full Name</Title>
                <StyledTextInput
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="org">
                <Title>How do you identify yourself ?</Title>
                <RadioGroup
                  aria-label="identity"
                  name="identity"
                  value={identity}
                  onChange={(event) => setIdentity(event.target.value)}
                >
                  <div style={{ display: 'flex' }}>
                    <FormControlLabel
                      value="Individual"
                      control={<Radio color="primary" />}
                      label="Individual"
                      className="label--signup"
                    />
                    <FormControlLabel
                      value="Organization"
                      control={<Radio color="primary" />}
                      label="Organization"
                      className="label--signup"
                    />
                  </div>
                </RadioGroup>
              </div>
            </div>
            {identity === 'Organization' ? (
              <>
                <Title>Legal name of your organization</Title>
                <StyledTextInput
                  variant="outlined"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </>
            ) : null}

            <Title>Official Email Address</Title>
            <StyledTextInput
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={
                validateEmail(email) !== true && 'Enter a valid email address'
              }
            />
            <Title>Phone Number</Title>
            <StyledTextInput
              variant="outlined"
              value={phone}
              onChange={(e) => handlePhone(e)}
              helperText={
                validatePhone(phone) !== true && 'Enter a valid Number'
              }
            />
            <div className="password">
              <span>
                <Title>Password</Title>
                <StyledTextInput
                  variant="outlined"
                  maxWidth="250px"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText={
                    password.length < 6 &&
                    password.length != 0 &&
                    'Password must be 6 characters long'
                  }
                />
              </span>
              <span className="password-confirm">
                <Title>Confirm Password</Title>
                <StyledTextInput
                  variant="outlined"
                  maxWidth="250px"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  helperText={
                    password !== passwordConfirm &&
                    passwordConfirm.length != 0 &&
                    'Passwords do not match'
                  }
                />
              </span>
            </div>
            {/* {referralId === 'NOREF' ? (
              <Button
                className="refbtn"
                color="primary"
                onClick={() => {
                  setRefId('');
                }}
              >
                Got Referral ID?
              </Button>
            ) : (
              <StyledTextInput
                maxWidth="200px"
                className="refbtn"
                variant="outlined"
                label="Enter Referral ID"
                size="small"
                name="referralId"
                type="text"
                value={referralId}
                onChange={(event) => setRefId(event.target.value)}
              />
            )} */}
            <br />
            <div className="info-text">
              <Checkbox
                onClick={handleCheck}
                checked={agreed}
                color="primary"
              />
              <p>
                Creating an account means you’re okay with our{' '}
                <span>Terms and Conditions of Service, Privacy Policy </span>
              </p>
            </div>
            {loading ? (
              <CircularProgress size={30} style={{ margin: '30px 5px' }} />
            ) : (
              <SignInBtn
                style={{
                  backgroundColor: handleDisable && 'lightGray',
                }}
                disabled={handleDisable}
                onClick={() => signup()}
              >
                Create Account
              </SignInBtn>
            )}
          </SignupContainer>
        </AuthContainer>
      </AuthWrapper>
      <Modal open={verifyModal} onClose={() => setVerifyModal(false)}>
        <ModalContent>
          {verificationError && (
            <Error>
              <InfoOutlinedIcon /> {verificationError}
            </Error>
          )}
          {panel === 1 && (
            <div className="panel-1">
              <p className="otp-text">
                Enter the OTP sent to the Registered Phone Number
              </p>
              <StyledTextInput
                inputProps={{ min: 0, style: { textAlign: 'center' } }}
                className="otp-input"
                variant="outlined"
                maxWidth="250px"
                value={enteredOtp}
                onChange={(e) => setEnteredOtp(e.target.value)}
              />

              <SignInBtn
                style={{ marginTop: '20px' }}
                onClick={() => {
                  updateVerification('phone', null);
                  console.log(userId);
                }}
              >
                Verify
              </SignInBtn>
            </div>
          )}

          {panel === 2 && (
            <div className="panel-2">
              <img src="/assets/images/check.png" alt="" />
              <p>Congratulations !!</p>
              <p>your phone has been Verified</p>
              <p>
                Check your <span>mail</span> to{' '}
                <span>complete the verfication </span> process
              </p>
            </div>
          )}
          {panel === 3 && (
            <div className="panel-3">
              <CloseIcon
                onClick={() => {
                  history.push('/signin');
                  setVerifyModal(false);
                  window.location.search = '';
                }}
              />
              <img src="/assets/images/congratulations.png" alt="" />
              <h5>Congratulations</h5>
              <p>your account has been Verified</p>
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default Signup;
