import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 100vh;
  overflow: hidden;
`;

export const AuthContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 2rem 5rem;
  font-family: 'Poppins' !important;
  overflow-y: scroll;

  a {
    text-decoration: none;
  }

  .upper-helper-text {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 16px;
    line-height: 42px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #4b5563;

    span {
      color: #336cfb;
      cursor: pointer;
    }

    @media (max-width: 550px) {
      font-size: 14px;
    }
  }

  @media (max-width: 550px) {
    padding: 2rem 2rem;
  }
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 42px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 8px;

  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

export const SigninWrapper = styled.div`
  padding: 4rem 0rem;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 550px) {
    overflow: hidden;
  }

  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: #000000;

    @media (max-width: 550px) {
      font-size: 22px;
    }
  }

  .login-option {
    margin: 1.4rem 0;
    color: #fff;
    width: 374px;
    height: 46px;
    background: #245fd3;
    border-radius: 4px;
    text-transform: uppercase !important;
    font-family: 'Poppins' sans-serif !important;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.04em;
    color: #f6f6f6;

    .MuiSvgIcon-root {
      margin-right: 15px;
    }
    &:hover {
      background: #245fd3;
    }

    @media (max-width: 550px) {
      width: calc(100vw - 32vw);
      height: 46px;
      font-size: 13px;
    }
  }

  .divider-01 {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    color: #b5b3c3;

    @media (max-width: 550px) {
      width: calc(100vw - 32vw);
    }

    &::before {
      content: '';
      position: absolute;
      top: 12px;
      left: 0;
      width: 166px;
      height: 1px;
      background-color: #b5b3c3;

      @media (max-width: 550px) {
        width: 40%;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 12px;
      right: 0;
      width: 166px;
      height: 1px;
      background-color: #b5b3c3;
      @media (max-width: 550px) {
        width: 40%;
      }
    }
  }

  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: -8px;

    p {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #245fd3;
      cursor: pointer;
      @media (max-width: 400px) {
        font-size: 10px;
      }
    }

    div {
      display: flex;
      align-items: center;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #b5b3c4;
        cursor: pointer;
        @media (max-width: 400px) {
          font-size: 10px;
        }
      }
    }
  }

  .signin-eye {
    position: absolute;
    top: 52px;
    right: 12px;
    color: #757575;
    cursor: pointer;

    @media (max-width: 370px) {
      right: 25px;
    }
  }
`;

export const ForgetModal = styled.div`
  background-color: #fff;
  height: 200px;
  width: 600px;
  padding: 3rem 2rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .MuiSvgIcon-root {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .forgot-1 {
    display: flex;
    flex-direction: column;
  }

  .forgot-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -1.5rem;

    @media (max-width: 550px) {
      margin-top: -17px;
    }

    img {
      width: 8rem;
      margin-bottom: 10px;

      @media (max-width: 550px) {
        width: 5rem;
      }
    }

    p {
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #696777;

      @media (max-width: 700px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 700px) {
    width: calc(100vw - 15vw);
  }
  @media (max-width: 550px) {
    height: 160px;
    padding: 2rem 1rem;
    padding-top: 2rem;
  }
`;

export const SignupContainer = styled.div`
  padding: 5px 0rem;
  width: 100%;
  /* overflow-x: hidden; */

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .name-org {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 850px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .org {
    margin-left: 3rem;

    @media (max-width: 850px) {
      margin-left: 0;
    }
  }

  .password {
    display: flex;

    @media (max-width: 680px) {
      flex-direction: column;
    }
  }

  .password-confirm {
    margin-left: 1rem;
    @media (max-width: 680px) {
      margin-left: 0;
    }
  }

  .refbtn {
    margin-top: 1rem;
  }

  .info-text {
    margin-top: 10px;
    margin-left: -8px;
    display: flex;
    align-items: flex-end;
    width: 500px;

    p {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #b5b3c4;

      span {
        color: #336cfb;
        cursor: pointer;
      }
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }
`;

export const StyledTextInput = styled(TextField)`
  && {
    font-family: 'Poppins' !important;
    width: 380px;
    max-width: ${({ maxWidth }) => maxWidth || '480px'};

    .MuiOutlinedInput-input {
      padding: 12.5px 14px;
    }
    .MuiFormHelperText-contained {
      margin-left: 0;
      color: red;

      @media (max-width: 400px) {
        font-size: 10px;
      }
    }
    .MuiFormLabel-root {
      line-height: 0.5;
    }

    @media (max-width: 550px) {
      width: calc(100vw - 32vw) !important;
    }
  }
`;

export const ResetContainer = styled.div`
  margin-top: 6rem;
  h1 {
    color: #4b5563;
    @media (max-width: 550px) {
      font-size: 22px;
    }
  }
`;

export const SignInBtn = styled(Button)`
  && {
    width: 180px;
    background: #245fd3;
    text-transform: none;
    margin-top: 20px;
    font-size: 16px;
    color: #fff;

    @media (max-width: 500px) {
      width: max-content;
      padding: 10px 20px;
      font-size: 13px;
    }

    &:hover {
      background: #245fd3;
    }
  }
`;

export const Error = styled.div`
  margin: 10px 0;
  background: rgb(253, 236, 234);
  padding: 0.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgb(97, 26, 21);
  padding-left: 1rem;

  .MuiSvgIcon-root {
    color: red;
    margin-right: 10px;
  }

  @media (max-width: 550px) {
    width: calc(100vw - 32vw);
  }
`;

export const ModalContent = styled.div`
  width: 550px;
  height: 250px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 10px;

  @media (max-width: 600px) {
    width: calc(100vw - 10vw);
  }

  .panel-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .otp-text {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #696777;
    margin-bottom: 2rem;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 1rem;
    }
  }

  .panel-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 8rem;
    }
    p {
      margin-top: 10px;
      font-family: 'Poppins';
      font-size: 18px;
      letter-spacing: 0.02em;
      color: #696777;
    }

    span {
      color: #336cfb;
      text-align: center;
    }

    @media (max-width: 600px) {
      img {
        width: 5rem;
      }
      line-height: 20px;
      p,
      span {
        text-align: center;
        font-size: 14px;
      }
    }
  }

  .panel-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .MuiSvgIcon-root {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    img {
      width: 12rem;
    }

    h5 {
      font-size: 20px;
      margin: 10px 0;
      font-weight: 600;
    }

    p {
      text-transform: capitalize;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #b5b3c4;
    }
    @media (max-width: 600px) {
      img {
        width: 8rem;
      }

      h5 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;
