import React from 'react';
import './expectedPreview.scss';

const ExpectedPreview = ({ budget,form }) => {
  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
      : '0';
  }
  return (
    <>
      <div className="expected-preview">
        <h3 className="title">Expected No. of {!form ? "Clicks" :"Submission" }</h3>
        <div className="expected-clicks">
          <div>
            <img src="/assets/images/expectedClicks.png" alt="" />
            <p>{nFormatter(Math.floor(budget / 10), 0)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpectedPreview;
