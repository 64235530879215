import React, { useState, useEffect } from "react";
import Modal from "../../../helpers/Modal";
import GuideLines from "../../../pages/GuideLines";
import styled from "styled-components";
import {
  Snackbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TopBar } from "../TopBar";
import { Table } from "../Table";
import { Close } from "@material-ui/icons";
import axios from "axios";
import AddIcon from '@mui/icons-material/Add';
const _ = require("lodash"); 
const api = process.env.REACT_APP_API;
const paytmapi = process.env.REACT_APP_PAYTM;
const mid = process.env.REACT_APP_MID;

const CompletedPage = () => {
  const [guidlines, setGuidlines] = useState(false);
  const [msg, setMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false || "");
  const [handleModal, setHandleModal] = useState(false);
  const [type, setType] = useState("Value");
  const [selectedId, setSelectedId] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [handleCompletedModal, setHandleCompletedModal] = useState("");
  const [handleMailModal, setHandleMailModal] = useState("");
  const [handleErrorModal, setHandleErrorModal] = useState("");
  const [tableData, setTableData] = useState([]);
  const [activeId, setActiveId] = useState("");
  const [tableDataFilter, setTableDataFilter] = useState([]);
  const [total, setTotal] = useState(10);
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState("");
  const [feedbacktext, setFeedbacktext] = useState("");
  const [qnty, setQnty] = useState(null);

  var data = [];
  const submitFeedback = (id) => {
    console.log("Submit feedback", id);
    if (typeSelected === "promo") {
      let body = {};
      if (type == "Value") {
        body = {
          id: id,
          value: qnty ? qnty : 0,
          feedback: feedbacktext ? feedbacktext : "",
        };
      } else {
        body = {
          id: id,
          volume: qnty ? qnty : 0,
          feedback: feedbacktext ? feedbacktext : "",
        };
        console.log(body)
        axios
          .post(`${api}/socials/feedback`, body)
          .then((response) => console.log(response.data))
          .catch((error) => console.log(error));
      }
    } else {
      const body = {
        id: id,
        volume: null,
        feedback: feedbacktext ? feedbacktext : "",
      };
      console.log(body)
      axios
      .post(`${api}/survey/feedback`, body)
      .then((response) => console.log(response.data))
      .catch((error) => console.log(error));
    }
  };
  ///survey/feedback (post with auth)
  //socials/feedback
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const searchSurvey = (search) => {
    const filteredData = tableDataFilter.filter(
      (item) => !search || item._id.toLowerCase().includes(search.toLowerCase())
    );

    setTableData(filteredData);
  };

  const sendMail = async (id) => {
    console.log(`mail sended tp ${id}`);
    const data = {
      survey: id,
    };
    axios
      .post(`${api}/survey/report`, data)
      .then((response) => {
        console.log(response.data);
        setHandleMailModal(false);
      })
      .catch((e) => {
        console.log(e);
        setError("Some problem in sending mail try again after some time");
        setHandleMailModal(false);
        setHandleErrorModal(true);
      });
  };

  const deleteExisting = (id, type) => {
    console.log("deleted id", id, type);
    let t = "socials";
    if (type !== "promo") t = type;

    axios
      .post(`${api}/${t}/delete/${id}`)
      .then((response) => {
        console.log("response", response.data);
        if (response.status === 200) {
          const newTableData = tableData.filter((item) => item._id !== id);
          setTableData(newTableData);
          setHandleCompletedModal(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setError(`Some problem in deleting ${type} try again after some time`);
        setHandleCompletedModal(false);
        setHandleErrorModal(true);
      });
  };

  useEffect(() => {
    axios.defaults.headers["x-auth-token"] = window.localStorage.getItem("jwt");
  }, []);
  useEffect(() => {
    axios
      .get(`${api}/socials/mypromo`)
      .then((response) => {
        console.log(response.data);
        setTotal(response.data.length);
        data = response.data.promo;
        // setFetching(false)
        // console.log('tableData',tableData)
        axios
          .get(`${api}/survey/mysurvey`)
          .then((response) => {
            console.log(response.data);
            const newArray = data.concat(response.data.survey);
            const filterArray = newArray.filter(item=> item.status === 'Completed' || item.status === 'Rejected')
            setTotal(filterArray.length);
            setTableData(_.orderBy((data.concat(response.data.survey)).filter(item=> item.status === 'Completed' || item.status === 'Rejected'), ['updatedAt'], 
            ['desc']));
            setTableDataFilter(_.orderBy((data.concat(response.data.survey)).filter(item=> item.status === 'Completed' || item.status === 'Rejected'), ['updatedAt'], 
            ['desc']));
            console.log(
              "XXXXXXXXXXX",
              data,
              tableData,
              data.concat(response.data.survey)
            );
            setFetching(false);
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        message={msg}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <TopBar searchSurvey={searchSurvey} search={true} />
      <h1>Completed Promo</h1>
      <div className="existing">
        <div className="heading">
          <Table
            setHandleModal={setHandleModal}
            setHandleCompletedModal={setHandleCompletedModal}
            setTypeSelected={setTypeSelected}
            setSelectedId={setSelectedId}
            setTotal={setTotal}
            total={total}
            fetching={fetching}
            tableData={tableData}
            setTableData={setTableData}
            tableDataFilter={tableDataFilter}
            completed={true}
            setHandleMailModal={setHandleMailModal}
          />
        </div>
      </div>
      <div className=""style={{    bottom: "20px",
    right: "30px",
    height: "60px",
    width: "60px",
    backgroundColor:"#336cfb",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    cursor: "pointer",
    }}>
      <AddIcon fontSize="large" style={{color: "white"}} onClick={()=>{window.location.reload()}}/>
    </div>
      <Modal open={guidlines} handleClose={() => setGuidlines(false)}>
        <GuideLines handleClose={() => setGuidlines(false)} />
      </Modal>
      <Modal
        open={handleCompletedModal}
        handleClose={() => setHandleCompletedModal(false)}
      >
        <CompletedModalContainer>
          <Close onClick={() => setHandleCompletedModal(false)} />
          <p>
            Are you sure to delete the {typeSelected} with id: {selectedId}?
          </p>
          <Button
            className="btn-submit"
            onClick={() => deleteExisting(selectedId, typeSelected)}
            variant="contained"
          >
            Delete {typeSelected}
          </Button>
        </CompletedModalContainer>
      </Modal>
      <Modal
        open={handleMailModal}
        handleClose={() => setHandleMailModal(false)}
      >
        <CompletedModalContainer>
          <Close onClick={() => setHandleMailModal(false)} />
          <p style={{ fontSize: 16 }}>
            A Mail will be sent to your registered email id containing response
            of {typeSelected} with id: {selectedId}.
            <br />
            Do you want to proceed?
          </p>
          <Button
            className="btn-submit"
            onClick={() => sendMail(selectedId)}
            variant="contained"
          >
            Send {typeSelected}
          </Button>
        </CompletedModalContainer>
      </Modal>
      <Modal
        open={handleErrorModal}
        handleClose={() => setHandleErrorModal(false)}
      >
        <CompletedModalContainer>
          <Close onClick={() => setHandleErrorModal(false)} />
          <p style={{ color: "red" }}>{error}</p>
        </CompletedModalContainer>
      </Modal>
      <Modal open={handleModal} handleClose={() => setHandleModal(false)}>
        <ModalContainer>
          <Close onClick={() => setHandleModal(false)} />
          {typeSelected === "promo" ? (
            <div className="topModal">
              <p>
                Please report your actual sales in volume (in volume/ value)
              </p>
              <InputContainer>
                <select
                  onChange={(e) => setType(e.target.value)}
                  name="type"
                  id="type"
                >
                  <option value="Value">Value</option>
                  <option value="Volume">Volume</option>
                </select>
                <input value={qnty} onChange={(e) => setType(e.target.value)}className=".inputTop" placeholder={type} type="text" />
              </InputContainer>
            </div>
          ) : null}
          <p>Any suggestions you have for us?</p>
          <textarea value={feedbacktext} onChange={(e)=>setFeedbacktext(e.target.value)} className=".inputTop" type="textarea" />
          <Button
            onClick={() => {
              submitFeedback(selectedId);
            }}
            className="btn-submit"
            variant="contained"
          >
            Submit
          </Button>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default CompletedPage;

export const CompletedModalContainer = styled.div`
  width: 720px;
  height: 325px;
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 750px) {
    width: calc(100vw - 10vw);
    height: 220px;
    padding: 1.5rem 3rem;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 2%;
    cursor: pointer;
  }


  }
  Button{
    margin:2rem 0rem;
    background: #185ADB !important;
    padding: 4px 61px 4px 61px;
    a{
      text-decoration: none;
    color: #fff;
    font-size: 16px;
    }
    @media (max-width: 650px)
    {
      font-size:12px;
    }

  }
  textarea{
        resize: none;
        height:120px;
        width:100%;
        background: #E8F4FF;
        padding: 1.2rem;
border-radius:8px;
        @media (max-width: 650px)
        {
          height: 90px;
        }
      }
  p {
    margin: .85rem 0rem;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    width: 100%;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 16px;
      line-height: 14px;
    }
  }
  .topModal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    width:100%;
    margin:1rem 0rem;

      p {
    margin: 0.5rem 0rem;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
   
    width: 100%;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const ModalContainer = styled.div`
  width: 720px;
  height: 495px;
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 750px) {
    width: calc(100vw - 10vw);
    height: 290px;
    padding: 1.5rem 3rem;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 2%;
    cursor: pointer;
  }


  }
  Button{
    margin:2rem 0rem;
    background: #185ADB !important;
    font-size:14px;
    color:#fff !important;
    padding: 4px 61px 4px 61px;
    @media (max-width: 650px)
    {
      font-size:12px;
    }

  }
  textarea{
        resize: none;
        height:120px;
        width:100%;
        background: #E8F4FF;
        padding: 1.2rem;
border-radius:8px;
        @media (max-width: 650px)
        {
          height: 90px;
        }
      }
  p {
    margin: .85rem 0rem;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    width: 100%;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .topModal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    width:100%;
    margin:1rem 0rem;

      p {
    margin: 0.5rem 0rem;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
   
    width: 100%;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;
export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  select {
    margin-right: 10px;

    border-radius: 8px;
    height: 32px;
    padding: 6px 13px 6px 13px;

    background: #e8f4ff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    height: 32px;
    border: none !important;
    outline: none !important;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #f0f2f4;
    padding: 6px 13px 6px 13px;
  }
  input[type="checkbox"]
`;

export const ErrorContainer = styled.div`
  padding: 4rem;
  background-color: #fff;
  border-radius: 10px;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05em;
  color: red;
  @media (max-width: 650px) {
    font-size: 15px;
    line-height: 20px;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
    fill: #000;
  }
`;
