import React, { useEffect, useState } from "react";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Resizer from "react-image-file-resizer";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import ExpectedPreview from "../ExpectedPreview/ExpectedPreview";
import InAppPreview from "../InAppPreview/InAppPreview";
import axios from "axios";
import { useLocation } from "react-router";
import { Modal } from "../../../helpers";
import { ErrorContainer, ModalContainer } from "../Dashboard";
import Close from "@material-ui/icons/Close";
import { GuideLines } from "../../../pages/_index";
import { TopBar } from "../TopBar";
import { FormPage } from "../../_index";

const useStyles = makeStyles((theme) => ({
  titleInput: {
    width: "15rem",
    marginBottom: "2.5rem",
    "& .MuiFormHelperText-contained": {
      marginLeft: 2,
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    "& .MuiInputBase-input": {
      height: ".6rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  desInput: {
    width: "80%",
    minWidth: "16rem",
    marginBottom: 20,
    "& .MuiFormHelperText-contained": {
      marginLeft: 2,
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      height: "2.1876em !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
  },
  linkInput: {
    width: "20rem",
    marginBottom: "2.2rem",
    "& p": {
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      height: ".6em !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "16rem",
    },
  },
  budgetInput: {
    "& .MuiInputBase-root": {
      color: "#336fcb",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    "& .MuiInputBase-input": {
      height: ".6rem !important",
    },
  },
}));

const api = process.env.REACT_APP_API;
const paytmapi = process.env.REACT_APP_PAYTM;
const mid = process.env.REACT_APP_MID;

const FormPageContainer = ({ form, id }) => {
  const location = useLocation();
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [link, setLink] = useState("");
  const [budget, setBudget] = useState("");
  const [error, setError] = useState("");
  const [imgLink, setImgLink] = useState(null);
  const [errMsg, setErrMsg] = useState("");

  const [logoUrl, setLogoUrl] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");

  const [checked, setChecked] = useState(false);
  const [msg, setMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false || "");

  const [userId, setUserId] = useState("");

  const [socialId, setSocialId] = useState("");
  const [txnToken, setTxnToken] = useState("");
  const [order, setOrder] = useState({});

  const [loading, setLoading] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [completedModal, setCompletedModal] = useState(false);
  const [guidlines, setGuidlines] = useState(false);
  const [selectedValue, setSelectedValue] = useState("promo");

  const [questions, setQuestions] = useState([]);
  const [fetching,setFetching] = useState(true);
  useEffect(() => {
    if (form) {
      setBudget(form.budget);
      setDesc(form.description);
      setTitle(form.title);
      // setLogoUrl(form.logo)
      setPreviewLogo(form.logo);
      setImgLink(form.logo);
      setQuestions(form.questions);
    } else {
      var newQuestion = {
        questionText: "Question",
        // answer: false,
        // answerKey: "",
        questionType: "radio",
        options: [{ optionText: "Option 1" }],
        open: true,
        required: false,
      };

      setQuestions([...questions, newQuestion]);
    }
  }, []);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    // console.log(selectedValue)
  };

  useEffect(() => {
    var params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    console.log("params", params);

    if (params.paymentId != null) {
      setPaymentId(params.paymentId);
    }

    if (params.msg != null) {
      setCompletedModal(true);
      setPaymentId(null);
      setErrMsg(params.msg);
    }
  }, [location]);

  console.log("..---->", paymentId);

  useEffect(() => {
    if (paymentId != null) {
      setCompletedModal(true);
      updateSocialPayments(paymentId);
    }
  }, [paymentId]);

  const handleBudget = (e) => {
    let value = e.target.value;
    if (isNaN(value)) return false;
    setBudget(e.target.value);
  };

  const handleCompression = (file) => {
    return new Promise((resolve, reject) => {
      try {
        Resizer.imageFileResizer(
          file,
          1000,
          1000,
          "JPEG",
          90,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
        );
      } catch (err) {
        console.log(err);
        resolve(file);
      }
    });
  };

  const handleLogo = (event) => {
    console.log("Enter in ", event?.target.files[0]);
    if (event?.target?.files[0]) {
      handleCompression(event?.target?.files[0]).then((file) => {
        console.log("file", file);
        setLogoUrl(file);
        var reader = new FileReader();
        reader.onload = function (base64) {
          setPreviewLogo(base64.target.result);
        };
        reader.readAsDataURL(file);
        // console.log('PreviewLogo',logoUrl);
      });
    }
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  function validURL(link) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(link);
  }

  const handleLink = (e) => {
    setLink(e.target.value);
    validURL(e.target.value);
  };

  const uploadLogo = () => {
    let logoData = new FormData();

    if (logoUrl !== null) {
      logoData.append("files", logoUrl, logoUrl.name);
      try {
        axios
          .post(`${api}/upload`, logoData)
          .then((res) => {
            setImgLink(res.data.link ? res.data.link : res.data[0].id);
            console.log("upload logo res", res.data);
            return true;
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    }
    return true;
  };

  const handlePublish = async () => {
    if (title === "") {
      setOpenSnackbar(true);
      setMsg("Please Enter a Title");
      return;
    }
    if (title.length >= 30) {
      setOpenSnackbar(true);
      setMsg("Title must be less than 30 characters");
      return;
    }
    if (desc === "") {
      setOpenSnackbar(true);
      setMsg("Please give a Description");
      return;
    }
    if (desc.length >= 50) {
      setOpenSnackbar(true);
      setMsg("Description must be less than 50 characters");
      return;
    }
    // if (link === "") {
    //   setOpenSnackbar(true);
    //   setMsg("Please provide a Link");
    //   return;
    // }
    if (previewLogo === "") {
      setOpenSnackbar(true);
      setMsg("Please provide a logo");
      return;
    }
    if (budget === 0 || budget === "") {
      setOpenSnackbar(true);
      setMsg("Please Enter your Budget");
      return;
    }

    if (budget <= 999) {
      setOpenSnackbar(true);
      setMsg("Enter a minimun budget of 1000");
      return;
    }

    const data = {
      title,
      description: desc,
      budget,
      questions: questions,
      logo: imgLink,
    };
    console.log("Data to be submitted", data,imgLink);
    setLoading(true);

    try {
      if (imgLink !== null) {
        if (!id) {
          const res = await axios.post(`${api}/survey`, data);
          console.log("res sending data", res);
          localStorage.setItem("socialId", res.data.id);
          setSocialId(res.data.id);
          updateSocials(res.data.id);
          setLoading(false);
        } else {
          const res = await axios.post(`${api}/survey/edit/${id}`, data);
          console.log("res editing data", res.data,res?.data?.survey?.id);
          localStorage.setItem("socialId", res?.data?.survey?.id);
          setSocialId(res?.data?.survey?.id);
          updateSocials(res?.data?.survey?.id);
          // const socialID = res.data.survey.id
          // startPay(res.data.survey.id)
          setLoading(false);

        }
      }
    } catch (error) {
      console.log("error sending data", error.message);
      alert(error.message)
      setLoading(false);
    }
  };

  const updateSocialPayments = async (id) => {
    const data = {
      payId: id,
    };
    const sid = localStorage.getItem("socialId");
    // const res = await axios.post(`${api}/socials/addPayment/${sid}`, data);
    // console.log(res);
  };

  const updateSocials = async (id) => {
    const data = {
      payId: id,
    };
    const ID = localStorage.getItem("userId");
    // const res = await axios.post(`${api}/socialusers/addSocial/${ID}`, data);
    // console.log(res);
  };

  useEffect(() => {
    uploadLogo();
  }, [logoUrl]);

  useEffect(() => {
    if (socialId) {
      startPay();
    }
  }, [socialId]);

  useEffect(() => {
    if (txnToken && order.OrderID) {
      console.log("txnToken", txnToken);
      console.log("Order ID", order.OrderID);
      displayPaytm();
    }
  }, [txnToken, order.OrderID]);

  const startPay = (socialID) => {
    console.log("startpay",socialID)
    var data = {
      Social: socialId,
      // Social: socialID,
      Amount: budget,
      promoType:"survey"
    };
    console.log("startpay",socialID)
    axios
      .post(`${api}/socialspayments`, data)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 200) {
          console.log("initiated");
          setTxnToken(data.txnToken);
          setOrder(data.order);
        } else {
          setError(true);
          console.log("err 0");
          setMsg(data.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        setError(true);
        setMsg(err.message);
        setLoading(false);
      });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayPaytm = async () => {
    var paytmApi = `${paytmapi}/merchantpgpui/checkoutjs/merchants/${mid}.js`;
    console.log(paytmApi);
    const res = await loadScript(paytmApi);
    if (!res) {
      setError(true);
      setLoading(false);
      setMsg("Paytm failed to load");
      return;
    }

    var config = {
      root: "",
      flow: "WEBSTAGING",
      data: {
        orderId: order.OrderID /* update order id */,
        token: txnToken /* update token value */,
        tokenType: "TXN_TOKEN",
        amount: budget /* update amount */,
      },
      header: window.location.origin,
      handler: {
        notifyMerchant: function (eventName, data) {
          console.log("eventName => ", eventName);
          console.log("data => ", data);
          setLoading(false);
        },
      },
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            // after successfully updating configuration, invoke Blink Checkout
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {
            console.log("error => ", error);

            setError(true);
            setMsg(error.message);
            setLoading(false);
          });
      });
    }
  };

  useEffect(()=>{
    axios.defaults.headers["x-auth-token"] = window.localStorage.getItem("jwt");
  },[])


  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        message={msg}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />

      <div className={`formcreate ${id ? "edit__mode__addon" : ""}`}>
        <div className="left__form">
          <div className="top">
            <h2>Title</h2>
            <TextField
              variant="outlined"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              helperText={
                title?.length !== 0 && title?.length >= 30 && "Maximum length: 30"
              }
            />
          </div>
          <div className="bottom">
            <h2>Upload Your Image</h2>
            <span>
              <label className="imagepicker">
                <input type="file" onChange={handleLogo} />
                <PhotoLibraryIcon /> Add logo
              </label>
            </span>
            {/* <div className="imagepicker">
              <PhotoLibraryIcon />
              <h2>Add</h2>
            </div> */}
            <button
              onClick={() => {
                uploadLogo();
              }}
              className="btn"
            >
              Upload
            </button>
          </div>
        </div>
        <div className="right__form">
          <h2>Description</h2>
          <TextField
            variant="outlined"
            placeholder="Short Description"
            multiline
            rows={3}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            helperText={
              desc?.length !== 0 && desc?.length >= 50 && "Maximum length: 50"
            }
          />
        </div>
      </div>
      <Divider />
      <div className="create-formBudget">
        <span>
          <p className="title">Add your form budget </p>
          <p className="title">(in INR)</p>
        </span>
        <TextField
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          className={classes.budgetInput}
          value={budget}
          onChange={handleBudget}
          variant="outlined"
          placeholder="XXX"
        />
      </div>
      <Divider />
      <div className="form-preview">
        <div className="preview__left">
          <ExpectedPreview form={true} budget={budget && budget} />
        </div>
        <div className="preview__right">
          <InAppPreview
            title={title}
            description={desc}
            previewLogo={previewLogo}
          />
        </div>
      </div>
      <FormPage
        questions={questions}
        setQuestions={setQuestions}
        documentName={title}
        documentDescription={desc}
        id={123}
      />

      <div className="publish">
        <div className="publish--text">
          <Checkbox
            checked={checked}
            onChange={handleCheck}
            color="primary"
            style={{
              color: "#ffffff",
            }}
          />
          <p>
            By Clicking the Publish Button, you agree to{" "}
            <span
              onClick={() => setGuidlines(true)}
              style={{ cursor: "pointer" }}
            >
              Social's Promotion guidelines{" "}
            </span>
          </p>
        </div>
        <span className="publish--button">
          {loading ? (
            <CircularProgress size={30} style={{ color: "white" }} />
          ) : (
            <Button
              disabled={!checked}
              onClick={() => {
                handlePublish();
              }}
            >
              Pay to Publish
            </Button>
          )}
        </span>
      </div>
      <Modal
        open={completedModal}
        onClose={() => {
          setCompletedModal(false);
        }}
      >
        {errMsg && (
          <>
            <ErrorContainer>
              <Close
                onClick={() => {
                  console.log(paymentId);
                  setPaymentId(null);
                  setError("");
                  window.location.search = "";
                }}
              />
              {errMsg}
            </ErrorContainer>
          </>
        ) }
      </Modal>
      <Modal open={guidlines} handleClose={() => setGuidlines(false)}>
        <GuideLines handleClose={() => setGuidlines(false)} />
      </Modal>
    </>
  );
};

export default FormPageContainer;
