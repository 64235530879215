import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components/macro';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { makeStyles } from '@material-ui/core/styles';
import { Title, Wrapper } from './_styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 16,
    fontWeight: 500,
  },
  color: {
    color: '#245FD3',
    letterSpacing: '0.02em',
    fontSize: 16,
    fontWeight: 900,
  },
  subHeading: {
    fontWeight: 900,
    paddingLeft: '10px',
    color: '#245FD3',
    marginBottom: '1rem',
  },
  text: {
    paddingLeft: '10px',
    marginBottom: '1rem',
  },

  iframe: {
    width: 'calc(100vw - 40vw)',
    height: 'calc(100vw - 67vw)',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 10vw)',
      height: 'calc(100vw - 50vw)',
    },
  },
}));

const GuideLines = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <Container>
      <ClearSharpIcon onClick={handleClose} />
      <Title mb="1rem">Overview of our policies</Title>
      <Wrapper>
        <p style={{ marginBottom: 10 }}>
          We want to support a healthy digital promotion ecosystem—one that is
          trustworthy and transparent, and works for users, advertisers, and
          publishers. The purpose of these guidelines is to help you Create
          Social Promo campaigns that align with our Promotion guidelines listed
          below. These guidelines are designed not only to abide by laws but to
          ensure a safe and positive experience for our users. This means that
          our policies prohibit some content that we believe to be harmful to
          users and the overall promotion ecosystem. We use a combination of
          automated and human evaluation to ensure Social Promos comply with
          these policies. Our advertising policies cover four broad areas:
        </p>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              <span className={classes.color}>Prohibited content: </span>
              Content you can't promote on the Social Platform
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p className={classes.subHeading}>(a) - Counterfeit goods</p>
            <p className={classes.text}>
              Social Promo prohibits the sale or promotion for sale of
              counterfeit goods. Counterfeit goods contain a trademark or logo
              that is identical to or substantially indistinguishable from the
              trademark of another. They mimic the brand features of the product
              in an attempt to pass themselves off as a genuine product of the
              brand owner. This policy applies to the content of your Promo and
              your website or app.
            </p>
            <p className={classes.subHeading}>
              (b) - Dangerous products or services
            </p>
            <p className={classes.text}>
              We want to help keep people safe both online and offline, so we
              don't allow the promotion of some products or services that cause
              damage, harm, or injury. Examples of dangerous content:
              Recreational drugs (chemical or herbal); psychoactive substances;
              equipment to facilitate drug use; weapons, ammunition, explosive
              materials and fireworks; instructions for making explosives or
              other harmful products; tobacco products
            </p>
            <p className={classes.subHeading}>Enabling dishonest behavior </p>
            <p className={classes.text}>
              We value honesty and fairness, so we don't allow the promotion of
              products or services that are designed to enable dishonest
              behavior. Examples of products or services that enable dishonest
              behavior: Hacking software or instructions; services designed to
              artificially inflate promo or website traffic; fake documents;
              academic cheating services
            </p>
            <p className={classes.subHeading}> Inappropriate content</p>
            <p className={classes.text}>
              We value diversity and respect for others, and we strive to avoid
              offending users, so we don’t allow ads or destinations that
              display shocking content or promote hatred, intolerance,
              discrimination, or violence. Examples of inappropriate or
              offensive content: bullying or intimidation of an individual or
              group, racial discrimination, hate group paraphernalia, graphic
              crime scene or accident images, cruelty to animals, murder,
              self-harm, extortion or blackmail, sale or trade of endangered
              species, promos using profane language
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              <span className={classes.color}>Prohibited practices: </span>
              Things you can't do if you want to promote with us
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p className={classes.subHeading}>
              (a) - Abusing the Social Platform
            </p>
            <p className={classes.text}>
              We want promos across the Social Platform to be useful, varied,
              relevant, and safe for users. We don’t allow advertisers to run
              promos, content, or destinations that attempt to trick or
              circumvent our Promo review process. Examples of abuse of the
              Social Promo: promoting content that contains malware; "cloaking"
              or using other techniques to hide the true destination that users
              are directed to; "arbitrage" or promoting destinations for the
              sole or primary purpose of showing ads; promoting "bridge" or
              "gateway" destinations that are solely designed to send users
              elsewhere; advertising with the sole or primary intent of gaining
              public social network endorsements from the user; "gaming" or
              manipulating settings in an attempt to circumvent our policy
              review systems
            </p>
            <p className={classes.subHeading}> </p>
            <p className={classes.subHeading}>(b) - Data collection and use</p>
            <p className={classes.text}>
              We want users to trust that information about them will be
              respected and handled with appropriate care. As such, our
              promotion partners should not misuse this information, nor collect
              it for unclear purposes or without appropriate disclosures or
              security measures. Examples of user information that should be
              handled with care: full name; email address; mailing address;
              phone number; national identity, pension, social security, tax ID,
              health care, or driver's license number; birth date or mother's
              maiden name in addition to any of the above information; financial
              status; political affiliation; sexual orientation; race or
              ethnicity; religion Examples of irresponsible data collection &
              use: obtaining credit card information over a non-secure server,
              promotions that claim to know a user's sexual orientation or
              financial status, violations of our policies that apply to
              <span className={classes.subHeading}>
                interest-based advertising and remarketing
              </span>
            </p>
            <p className={classes.subHeading}>(c) - Misrepresentation</p>
            <p className={classes.text}>
              We want users to trust the promos on our platform, so we strive to
              ensure promos are clear and honest, and provide the information
              that users need to make informed decisions. We don’t allow promos
              or destinations that deceive users by excluding relevant product
              information or providing misleading information about products,
              services, or businesses. Examples of misrepresentation: omitting
              or obscuring billing details such as how, what, and when users
              will be charged; omitting or obscuring charges associated with
              financial services such as interest rates, fees, and penalties;
              failing to display tax or licence numbers, contact information, or
              physical address where relevant; making offers that aren't
              actually available; making misleading or unrealistic claims
              regarding weight loss or financial gain; collecting donations
              under false pretenses; "phishing" or falsely purporting to be a
              reputable company in order to get users to part with valuable
              personal or financial information
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              <span className={classes.color}>
                Restricted content and features:{' '}
              </span>
              Content you can promote, but with limitations
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{ marginBottom: 10 }}>
              The policies below cover content that is sometimes legally or
              culturally sensitive. Online advertising can be a powerful way to
              reach customers, but in sensitive areas, we also work hard to
              avoid showing these ads when and where they might be
              inappropriate. For that reason, we allow the promotion of the
              content below, but on a limited basis. These promotions may not
              show to every user in every location, and advertisers may need to
              meet additional requirements before their ads are eligible to run.
              Note that not all products, features, or networks are able to
              support this restricted content.
            </p>
            <p className={classes.subHeading}>(a) - Sexual content </p>
            <p className={classes.text}>
              Promos should respect user preferences and comply with legal
              regulations, so we don't allow certain kinds of sexual content in
              promos and destinations. Some kinds of sexual content in promos
              and destinations are allowed only if they comply with the policies
              below and don't target minors, but they will only show in limited
              scenarios based on user search queries, user age, and local laws
              where the ad is being served. Examples of restricted sexual
              content: Visible genitalia and female breasts, hook-up dating, sex
              toys, strip clubs, sexually suggestive live chat, and models in
              sexualized poses.
            </p>
            <p className={classes.subHeading}>(b) - Alcohol </p>
            <p className={classes.text}>
              We abide by local alcohol laws and industry standards, so we don’t
              allow certain kinds of alcohol-related advertising, both for
              alcohol and drinks that resemble alcohol. Some types of
              alcohol-related promos are allowed if they meet the policies
              below, don’t target minors, and target only countries that are
              explicitly allowed to show alcohol promos. Examples of restricted
              alcoholic beverages: beer, wine, sake, spirits or hard alcohol,
              Champagne, fortified wine, non-alcoholic beer, non-alcoholic wine,
              and non-alcoholic distilled spirits
            </p>
            <p className={classes.subHeading}>(c) - Copyrights </p>
            <p className={classes.text}>
              We abide by local copyright laws and protect the rights of
              copyright holders, so we don’t allow ads that are unauthorized to
              use copyrighted content.
            </p>
            <p className={classes.subHeading}>(d) - Gambling and games </p>
            <p className={classes.text}>
              We support responsible gambling advertising and abide by local
              gambling laws and industry standards, so we don’t allow certain
              kinds of gambling-related advertising. Gambling-related promos are
              allowed if they comply with the policies below and the advertiser
              has received the proper Google Ads certification. Gambling ads
              must target approved countries, have a landing page that displays
              information about responsible gambling, and never target minors.
              Check local regulations for the areas you want to target. Examples
              of restricted gambling-related content: physical casinos; sites
              where users can bet on poker, bingo, roulette, or sports events;
              national or private lotteries; sports odds aggregator sites; sites
              offering bonus codes or promotional offers for gambling sites;
              online educational materials for casino-based games; sites
              offering "poker-for-fun" games; non-casino-based cash game sites
            </p>
            <p className={classes.subHeading}>(e) - Healthcare and medicines</p>
            <p className={classes.text}>
              We are dedicated to following advertising regulations for
              healthcare and medicine, so we expect that ads and destinations
              follow appropriate laws and industry standards. Some
              healthcare-related content can’t be promoted at all, while others
              can only be promoted if the advertiser is certified with Social by
              reaching out to us via email. Check local regulations for the
              areas you want to target.
            </p>
            <p className={classes.subHeading}>(f) - Political content</p>
            <p className={classes.text}>
              We support responsible political advertising and expect all
              political ads and destinations to comply with local campaign and
              election laws for any areas they target. This policy includes
              legally mandated election “silence periods.” Examples of political
              content: promotion of political parties or candidates, political
              issue advocacy
            </p>
            <p className={classes.subHeading}>(g) - Financial services </p>
            <p className={classes.text}>
              We want users to have adequate information to make informed
              financial decisions. Our policies are designed to give users
              information to weigh the costs associated with financial products
              and services, and to protect users from harmful or deceitful
              practices. For the purposes of this policy, we consider financial
              products and services to be those related to the management or
              investment of money and cryptocurrencies, including personalized
              advice. When promoting financial products and services, you must
              comply with state and local regulations for any region or country
              that your ads target — for example, include specific disclosures
              required by local law.
            </p>
            <p className={classes.subHeading}>(h) - Trademarks</p>
            <p className={classes.text}>
              There are multiple factors that determine when trademarks can be
              used in ads. Along with the factors described in our Policy
              Center, these policies apply only when a trademark owner has
              submitted a valid complaint to Google.
            </p>
            <p className={classes.subHeading}>(i) - Legal requirements </p>
            <p className={classes.text}>
              You’re always responsible for ensuring that you comply with all
              applicable laws and regulations, in addition to Social Promotion
              Guidelines, for all of the locations where your ads are showing.
            </p>
            <p className={classes.subHeading}>
              (j) - Other restricted businesses
            </p>
            <p className={classes.text}>
              We restrict certain kinds of businesses from promoting with us to
              prevent users from being exploited, even if individual businesses
              appear to comply with our other policies. Based on our own
              continuous reviews, and feedback from users, regulators, and
              consumer protection authorities, we occasionally identify products
              or services that are prone to abuse. If we feel that certain kinds
              of businesses pose an unreasonable risk to user safety or user
              experience, then we may limit or stop related promos from running.
            </p>
            <p className={classes.subHeading}>
              (k) - Restricted ad formats and features
            </p>
            <p className={classes.text}>
              There are multiple factors that determine access to advanced promo
              formats and features on the Social Platform. Certain promo formats
              are not available for all advertisers until they meet our specific
              requirements.
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              <span className={classes.color}>Editorial and technical: </span>
              Quality standards for your promos, websites, and apps
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <p style={{ marginBottom: 10 }}>
              We want to deliver ads that are engaging for users without being
              annoying or difficult to interact with, so we've developed
              editorial requirements to help keep your ads appealing to users.
              We've also specified technical requirements to help users and
              advertisers get the most out of the variety of ad formats we
              offer.
            </p>
            <p className={classes.subHeading}>(a) - Editorial </p>
            <p className={classes.text}>
              In order to provide a quality user experience, Google requires
              that all promos, extensions, and destinations meet high
              professional and editorial standards. We only allow ads that are
              clear, professional in appearance, and that lead users to content
              that is relevant, useful, and easy to interact with. Examples of
              promotions that don't meet these editorial and professional
              requirements: overly generic promos that contain vague phrases
              such as "Buy products here" gimmicky use of words, numbers,
              letters, punctuation, or symbols such as FREE, f-r-e-e, and F₹€€!!
            </p>
            <p className={classes.subHeading}>
              (b) - Destination requirements{' '}
            </p>
            <p className={classes.text}>
              We want consumers to have a good experience when they click on an
              ad, so ad destinations must offer unique value to users and be
              functional, useful, and easy to navigate. Examples of promotions
              that don't meet destination requirements: a display URL that does
              not accurately reflect the URL of the landing page, such as
              "google.com" taking users to "ymail.com" sites or apps that are
              under construction, parked domains, or are just not working sites
              that are not viewable in commonly used browsers sites that have
              disabled the browser's back button
            </p>
            <p className={classes.subHeading}>(c) - Technical requirements</p>
            <p className={classes.text}>
              To help us keep promos clear and functional, advertisers must meet
              our technical requirements.
            </p>
            <p className={classes.subHeading}>(d) - Ad format requirements </p>
            <p className={classes.text}>
              In order to help you provide a quality user experience and deliver
              attractive, professional-looking promos, we only allow promos that
              comply with specific requirements for each promo format. Review
              the requirements for all promo formats that you're using. Note
              that we don't allow Non-family safe promos in image promos, video
              promos, and other non-text promo formats. Examples of Promo format
              requirements: character limits for the ad headline or body, image
              size requirements, file size limits, video length limits, aspect
              ratios
            </p>
          </AccordionDetails>
        </Accordion>
        <div>
          <p style={{ fontSize: 20, marginBottom: 10, fontWeight: 'bold' }}>
            About our policies
          </p>
          <p>
            Social Promo enables businesses of all sizes, from India, to promote
            a wide variety of products, services, applications, and websites on
            the Social Platform. We want to help you reach existing and
            potential customers and audiences. However, to help create a safe
            and positive experience for users, we listen to their feedback and
            concerns about the types of promos they see. We also regularly
            review changes in online trends and practices, industry norms, and
            regulations. And finally, in crafting our policies, we also think
            about our values and culture as a company, as well as operational,
            technical, and business considerations. As a result, we have created
            a set of policies that apply to all promotions on the Social
            Platform.
          </p>
          <p>
            Social requires that advertisers comply with all{' '}
            <span className={classes.color}>
              applicable laws and regulations
            </span>{' '}
            and the Social Promotion Guidelines described above. It's important
            that you familiarize yourself with and keep up to date on these
            requirements for the places where your business operates, as well as
            any other places your ads are showing. When we find content that
            violates these requirements, we may take it down from appearing, and
            in cases of repeated or egregious violations, we may stop you from
            promoting with us.
          </p>
        </div>
        <p style={{ fontSize: 20, margin: '10px 0', fontWeight: 'bold' }}>
          {' '}
          Need help?
        </p>
        If you have questions about our policies, let us know:{''}{' '}
        <a
          className={classes.color}
          href="mailto:socialtheapp.marketing@gmail.com"
        >
          Contact us
        </a>
      </Wrapper>
    </Container>
  );
};

export const Container = styled.div`
  background: white;
  padding: 25px;
  width: 100%;
  margin: auto;
  position: relative;

  & .MuiSvgIcon-root {
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: pointer;
  }
  @media (max-width: 990px) {
    width: calc(100% - 25%);
    padding: 12px 10px;
  }

  @media (max-width: 450px) {
    width: calc(100% - 6%);
  }
`;

export default GuideLines;
