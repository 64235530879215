import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import './styles.dash.scss';
import LeftTabBar from './LeftTabBar/LeftTabBar';
import { Modal } from '../../helpers';
import Close from '@material-ui/icons/Close';
import { Redirect } from 'react-router';
import Home from './pages/Home';
import RequestModification from './pages/RequestModification';
import Completed from './pages/Completed';
import PaymentPending from './pages/PaymentPending';
import PendingVerification from './pages/PendingVerification';
import RejectedPromo from './pages/RejectedPromo';
import ActivePromo from './pages/ActivePromo';
import IndividualDetails from './pages/IndividualDetails';

const AdminDashboard = (props) => {
  const [choice,setChoice] = useState(7);
  const [isActive,setIsActive] = useState(false);
  const selectedChoice = props.location.state?.choice || 1; 
  const pageChoice = (selectedChoice) =>  
  {
    switch (selectedChoice) {
      case 1:
        return  <PendingVerification email={props.location.state?.email}/>;
      case 2:
        return <RequestModification email={props.location.state?.email}/>
        
      case 3:
        return <ActivePromo email={props.location.state?.email}/>;
        
      case 4: return <Completed email={props.location.state?.email}/>;
      case 5: return <RejectedPromo email={props.location.state?.email}/>;
      case 6: return <PaymentPending email={props.location.state?.email}/>;
      case 7: return <Home/>
      case 8: return <IndividualDetails email={props.location.state?.email} id={props.location.state?.id} name={props.location.state?.name}/>
      default: return <></>
    }
  }
  useEffect(() =>{
    console.log('Choice in location',selectedChoice,'props',props.location.state);
    setChoice(selectedChoice);
  },[])
  return (
    <>
      <div className="dashboard">
        <LeftTabBar
        choice={choice}
        setChoice={setChoice}
        active={isActive}
        email={props.location.state?.email}
        />
        <div className="dashboard-content">
          {pageChoice(selectedChoice)}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;




















export const ModalContainer = styled.div`
  width: 700px;
  height: 350px;
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 750px) {
    width: calc(100vw - 10vw);
    height: 290px;
    padding: 1.5rem 3rem;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 2%;
    cursor: pointer;
  }

  img {
    width: 144px;
    height: 142px;
    @media (max-width: 750px) {
      width: 124px;
      height: 122px;
    }
  }

  p {
    margin-top: 10px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

export const ErrorContainer = styled.div`
  padding: 4rem;
  background-color: #fff;
  border-radius: 10px;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05em;
  color: red;
  @media (max-width: 650px) {
    font-size: 15px;
    line-height: 20px;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
    fill: #000;
  }
`;
