import React, { useState } from 'react';
import styled from 'styled-components';
import './styles.dash.scss';
import LeftTabBar from './LeftTabBar/LeftTabBar';
import { CompletedPage, CreatePage, ExistingPage,PromoPage,FormPage } from './_index';
import { Modal } from '../../helpers';
import Close from '@material-ui/icons/Close';
import { Redirect } from 'react-router';

const Dashboard = () => {
  const [create, setCreate] = useState(!false);
  const [existing, setExisting] = useState(false);
  const [completed, setCompleted] = useState(false);

  const selectCreate = async () => {
    setCreate(true);
    setExisting(false);
    setCompleted(false);
  };

  const selectExisting = async () => {
    setCreate(false);
    setExisting(true);
    setCompleted(false);
  };

  const selectCompleted = async () => {
    setCreate(false);
    setExisting(false);
    setCompleted(true);
  };

  return (
    <>
      <div className="dashboard">
        <LeftTabBar
          create={create}
          existing={existing}
          completed={completed}
          selectCreate={selectCreate}
          selectExisting={selectExisting}
          selectCompleted={selectCompleted}
        />
        <div className="dashboard-content">
          {create ? <CreatePage isCreate={true} /> : null}
        </div>

      </div>
      {/* <Modal open={existing || completed} handleClose={selectCreate}>
        <ModalContainer>
          <Close onClick={() => selectCreate()} />
          <img src="/assets/images/cheering.png" alt="" />
          <p>
            We have received your promo request. We'll make it live when we
            release our App tentatively in February'22
          </p>
        </ModalContainer>
      </Modal> */}
    </>
  );
};

export default Dashboard;

export const ModalContainer = styled.div`
  width: 700px;
  height: 350px;
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 750px) {
    width: calc(100vw - 10vw);
    height: 290px;
    padding: 1.5rem 3rem;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 2%;
    cursor: pointer;
  }

  img {
    width: 144px;
    height: 142px;
    @media (max-width: 750px) {
      width: 124px;
      height: 122px;
    }
  }

  p {
    margin-top: 10px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

export const ErrorContainer = styled.div`
  padding: 4rem;
  background-color: #fff;
  border-radius: 10px;
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05em;
  color: red;
  @media (max-width: 650px) {
    font-size: 15px;
    line-height: 20px;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 5%;
    right: 2%;
    cursor: pointer;
    fill: #000;
  }
`;
