import React from 'react'
import styled from 'styled-components';
const Promo = ({
    id,title,description,budget,startDate,link,logo,setHandleModal,setSelectedId,setTypeSelected,setLogoModal,setSelectedLogo
}) => {
  return (
    <PromoContainer >
    <PromoId><h2>{(id)?.substring(0,2)+"-"+(id)?.substring(2,10)}</h2></PromoId>
    <Title>
        <h2>{title}</h2>
        <p>
        {description}
        </p>
    </Title>
    <Details>
        <Top>
            <h2>Promo Budget</h2>
            <p>INR {budget}</p>
        </Top>
        {startDate && <Bottom>
       <><h2>Start Date</h2>
            <p>12/12/12</p></>
        </Bottom>}
    </Details>
    <Content>
        <Top>
            {link ?
             <h2>Website Link</h2>
            : <h2>Qusetionaire</h2>
            }
            {!true ? <p><a target="_blank" href={link}>{link.substring(0,7)}</a></p> : <Button
                // disabled={!checked}
                onClick={(e) => {
                  // handlePublish();
                  e.preventDefault();
                  window.open(link);
                    console.log('clicked')
                }}
                className="button"
              >
                View
              </Button>}
        </Top>
        {/* <Bottom>
            <h2>Product/Services</h2>
            {startDate && <p>12/12/12</p>}
        </Bottom> */}
    </Content>
    <LogoContainer>
        <Left><h2>Logo</h2>
            <img src="/assets/images/imagelogo.png" style={{width: "70%",margin:10,cursor: "pointer" }}
            onClick={(e)=>{
                e.preventDefault()
                // window.open(logo)
                setSelectedLogo(logo)
                setLogoModal(true)
            }}
             />
            </Left>
        <Right>
            <Button
                onClick={() => {
                    setHandleModal(true);
                    setSelectedId(id);
                    link
                    ? setTypeSelected("promo")
                    : setTypeSelected("survey");
                    }}
                    size="small"
                    variant="contained"
                    color="primary"
            >
                FeedBack
            </Button>            
        </Right>
    </LogoContainer>
    </PromoContainer>
  )
}

export default Promo

export const PromoContainer = styled.div`
    display: flex;
    min-height:200px;
    flex-direction:row;
    padding:30px;
    background-color: #ffffff;
    margin:20px 10px;
    border-radius:8px;
    box-shadow:0px 12px 26px rgba(16, 30, 115, 0.06);
`
export const PromoId = styled.div`
    h2{
        font-size:18px;
        font-weight:700;
        color:#4D535A;
    }
    display: flex;
    padding: 10px 20px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #E7DEDE;
    flex:0.15;
`
export const Title = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex:0.25;
    padding: 10px 20px;
    border-right: 1px solid #E7DEDE;
    h2{
        font-size:18px;
        line-height:1.2;
        font-weight:600;
        color:#4D535A;
    }
    p{
        font-size:14px !important;
        font-weight:300;
        color:#857F7F;
        text-align:left;
        line-height:17px;
    }
`
export const Details = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 10px 20px;
border-right: 1px solid #E7DEDE;
flex:0.2;
    h2{
        font-size:18px;
        line-height:1.2;
        font-weight:600;
        color:#4D535A;
        text-align:left;
    }
    p{
        font-size:14px !important;
        font-weight:300;
        color:#857F7F;
        text-align:left;
        line-height:17px;
        margin-top:5px;
    }

`
export const Content = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 10px 20px;
border-right: 1px solid #E7DEDE;
flex:0.2;
    h2{
        font-size:18px;
        line-height:1.2;
        font-weight:600;
        color:#4D535A;
        text-align:left;
    }
    p{
        font-size:14px !important;
        font-weight:300;
        color:#857F7F;
        text-align:left;
        line-height:17px;
        margin-top:5px;
    }
`
export const LogoContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
padding: 10px 20px;
flex:0.2;
    h2{
        font-size:18px;
        line-height:1.2;
        font-weight:600;
        color:#4D535A;
        text-align:left;
    }
    p{
        font-size:14px !important;
        font-weight:300;
        color:#857F7F;
        text-align:left;
        line-height:17px;
        margin-top:5px;
`
export const Top = styled.div`
width:100%;
display: flex;
justify-content: center;
align-items:space-around;
flex-direction:column;
flex:0.5;
margin-bottom:10px;
`
export const Bottom = styled.div`
flex:0.5;
width:100%;
display: flex;
justify-content: center;
align-items:space-around;
flex-direction:column;
`
export const Left = styled.div`
width:100%;
display: flex;
justify-content: center;
align-items:center;
flex-direction:column;
flex:0.5;
margin-bottom:10px;
`
export const Right = styled.div`
flex:0.5;
height:100%;
marginLeft:20px;
display: flex;
justify-content: center;
align-items:center;
flex-direction:column;
`

export const Button = styled.button`
          text-transform: capitalize;
          background-color: #336cfb;
          color: #fff;
          font-size: 13px;
          display: flex;
          justify-content: center;
          align-items:center;
          outline: none;
          cursor: pointer;
          padding: 14px 24px;
          margin:10px 20px;
          box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
          border-radius: 4px;
          line-height: 10px;
          border:none;
          justify-self: center;

          &:hover {
            background-color: #336cfb;
          }
        `