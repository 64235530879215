import React from 'react';
import { Navbar } from '../components/_index';
import { Content, SubTitle, Title } from './_styles';

const Disclaimers = () => {
  return (
    <>
      <div>
        <Navbar />
        <Title>Disclaimers</Title>
        <Content>
          <p style={{ fontStyle: 'italic' }}>
          THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL.
          </p>
          <br />
          
          <p>
         
DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY CASE.
          </p>
          <br />
         
          <p>
          WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.
          </p>
          <br />
       
          <p>
          YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY REGARDING ANY THIRD PARTY'S SERVICES NOR WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD PARTY'S / SERVICE PARTNER’S/ SP SERVICES.
          </p>
          <br />
          <p>ETARK DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF ANY APPLICABLE LAWS INCLUDING THE LAW APPLICABLE TO PRODUCTS AND SERVICES OFFERED BY THE SERVICE PARTNER OR LOGISTICS PARTNER. WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON SUCH INFORMATION.</p>
              <br />
          <p>THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL</p>
              <br/>
            
              <p>
              DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY CASE.    </p>
              <br/>
             
              <p>
              WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.  </p>
              <br/>
  
              <p>
              YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY REGARDING ANY THIRD PARTY'S SERVICES NOR WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD PARTY'S / SERVICE PARTNER’S/ SP SERVICES.       </p>
            <br/>
             
              <p>
              ETARK DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF ANY APPLICABLE LAWS INCLUDING THE LAW APPLICABLE TO PRODUCTS AND SERVICES OFFERED BY THE SERVICE PARTNER OR LOGISTICS PARTNER. WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON SUCH INFORMATION.        </p>
            <br/>
          
        </Content>
      </div>
    </>
  );
};

export default Disclaimers;
