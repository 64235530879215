import React, { useState,useEffect } from "react";
import Modal from "../../../helpers/Modal";
import GuideLines from "../../../pages/GuideLines";

import { Snackbar, IconButton ,Button} from "@material-ui/core";
import styled from "styled-components";

import CloseIcon from "@material-ui/icons/Close";
import { TopBar } from "../TopBar";
import { Table } from "../Table";
import { Link } from "react-router-dom";
import { Close } from "@material-ui/icons";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
const _ = require("lodash"); 
const api = process.env.REACT_APP_API;
const paytmapi = process.env.REACT_APP_PAYTM;
const mid = process.env.REACT_APP_MID;

const ExistingPage = () => {
  const [guidlines, setGuidlines] = useState(false);
  const [msg, setMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false || "");
  const [selectedId, setSelectedId] = useState("");
  const [handleModal, setHandleModal] = useState(false);
  const [tableData,setTableData] = useState([])
  const [tableDataFilter,setTableDataFilter] = useState([])
  const [total, setTotal] = useState(10);
  const [typeSelected, setTypeSelected] = useState("");
  const [fetching,setFetching]=useState(true);
  var data = []
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const searchSurvey = (search) => {
    
  
    const filteredData = tableDataFilter.filter(
      (item) =>
        !search || item._id.toLowerCase().includes(search.toLowerCase())
    )

    setTableData(filteredData);
  };



  useEffect(()=>{
    axios.defaults.headers["x-auth-token"] = window.localStorage.getItem("jwt");
  },[])

  useEffect(() => {
    
      
    axios.get(`${api}/socials/mypromo`).then((response) =>
    {
      console.log(response.data)
      setTotal(response.data.length);
      data = response.data.promo
      // setFetching(false)
      // console.log('tableData',tableData)
      axios.get(`${api}/survey/mysurvey`).then((response) =>
      {
        console.log(response.data)
        const newArray = data.concat(response.data.survey);
        setTotal(newArray.length);
        setTableData(_.orderBy((data.concat(response.data.survey)), ['updatedAt'], 
        ['desc']));
        setTableDataFilter(_.orderBy((data.concat(response.data.survey)), ['updatedAt'], 
        ['desc']));
      
        console.log('XXXXXXXXXXX',data,tableData,data.concat(response.data.survey))
        setFetching(false)
      }
    )
    .catch(e=>console.log(e))
    }
    )
    .catch(e=>console.log(e))
      


    // return () => {
    //   setTotal(tabledata.length);
    //   setTableData(tabledata)
    // };
  }, []);
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        message={msg}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <TopBar searchSurvey={searchSurvey} search={true} />
      <h1>Your Promo</h1>
      <div className="existing">
        <div className="heading">
        
          <Table
            setHandleModal={setHandleModal}
            setSelectedId={setSelectedId}
            setTotal={setTotal}
            total={total}
            tableData={tableData}
            fetching={fetching}
            setTableData={setTableData}
            setTypeSelected={setTypeSelected}
            tableDataFilter={tableDataFilter}
          />
           
        </div>
        
      </div>
      <div className=""style={{    bottom: "20px",
    right: "30px",
    height: "60px",
    width: "60px",
    backgroundColor:"#336cfb",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    cursor: "pointer",
    }}>
      <AddIcon fontSize="large" style={{color: "white"}} onClick={()=>{window.location.reload()}}/>
    </div>
      <Modal open={guidlines} handleClose={() => setGuidlines(false)}>
        <GuideLines handleClose={() => setGuidlines(false)} />
      </Modal>
      <Modal open={handleModal} handleClose={() => setHandleModal(false)}>
        <ModalContainer>
          <Close onClick={() => setHandleModal(false)} />
          <p>Are you sure to edit the {typeSelected} with id: {selectedId}?</p>
          {/* <textarea className=".inputTop" type="textarea" /> */}
          
          {
            typeSelected === "promo" ? <Link to={`promo/${selectedId}`}><Button className="btn-submit" style={{color:"white"}} variant="contained">Edit</Button></Link>:<Link to={`form/${selectedId}`}><Button className="btn-submit" style={{color:"white"}} variant="contained">Edit</Button></Link>
          }
         
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ExistingPage;

export const ModalContainer = styled.div`
  width: 720px;
  height: 325px;
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 750px) {
    width: calc(100vw - 10vw);
    height: 220px;
    padding: 1.5rem 3rem;
  }

  .MuiSvgIcon-root {
    position: absolute;
    top: 3%;
    right: 2%;
    cursor: pointer;
  }


  }
  Button{
    margin:2rem 0rem;
    background: #185ADB !important;
    padding: 4px 61px 4px 61px;
    a{
      text-decoration: none;
    color: #fff;
    font-size: 16px;
    }
    @media (max-width: 650px)
    {
      font-size:12px;
    }

  }
  textarea{
        resize: none;
        height:120px;
        width:100%;
        background: #E8F4FF;
        padding: 1.2rem;
border-radius:8px;
        @media (max-width: 650px)
        {
          height: 90px;
        }
      }
  p {
    margin: .85rem 0rem;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    width: 100%;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 16px;
      line-height: 14px;
    }
  }
  .topModal{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    width:100%;
    margin:1rem 0rem;

      p {
    margin: 0.5rem 0rem;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
   
    width: 100%;
    letter-spacing: 0.02em;
    color: #26252e;
    @media (max-width: 650px) {
      font-size: 12px;
      line-height: 14px;
    }
  }
`;
