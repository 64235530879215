import React, { useEffect, useState } from "react";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Resizer from "react-image-file-resizer";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import ExpectedPreview from "../ExpectedPreview/ExpectedPreview";
import InAppPreview from "../InAppPreview/InAppPreview";
import axios from "axios";
import { useLocation } from "react-router";
import { Modal } from "../../../helpers";
import { ErrorContainer, ModalContainer } from "../Dashboard";
import Close from "@material-ui/icons/Close";
import { useParams } from "react-router";
import { GuideLines } from "../../../pages/_index";
import { TopBar } from "../TopBar";
import { FormPage } from "../_index";
import { PromoPage } from "../_index";
import LeftTabBar from "../LeftTabBar/LeftTabBar";

const useStyles = makeStyles((theme) => ({
  titleInput: {
    width: "15rem",
    marginBottom: "2.5rem",
    "& .MuiFormHelperText-contained": {
      marginLeft: 2,
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    "& .MuiInputBase-input": {
      height: ".6rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  desInput: {
    width: "80%",
    minWidth: "16rem",
    marginBottom: 20,
    "& .MuiFormHelperText-contained": {
      marginLeft: 2,
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      height: "2.1876em !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
  },
  linkInput: {
    width: "20rem",
    marginBottom: "2.2rem",
    "& p": {
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      height: ".6em !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "16rem",
    },
  },
  budgetInput: {
    "& .MuiInputBase-root": {
      color: "#336fcb",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    "& .MuiInputBase-input": {
      height: ".6rem !important",
    },
  },
}));

const api = process.env.REACT_APP_API;
const paytmapi = process.env.REACT_APP_PAYTM;
const mid = process.env.REACT_APP_MID;

const EditPage = ({ routeStep }) => {
  const [error, setError] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false || "");
  const [loading, setLoading] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [completedModal, setCompletedModal] = useState(false);
  const [guidlines, setGuidlines] = useState(false);
  const [selectedValue, setSelectedValue] = useState("promo");
  const [step, setStep] = useState()
  const { id } = useParams();
  const [apidata, setApidata] = useState([]);
  const [fetching,setFetching] = useState(true);


  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    console.log(selectedValue)
  };
  useEffect(() => {
    axios.defaults.headers["x-auth-token"] = window.localStorage.getItem("jwt");
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };


 
  const stepFunction = () => {
    switch (step) {
      case 1:
        return (
          <>
            {fetching? <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90%",
                    width: "100%",
                  }}
                >
                  <CircularProgress style={{ marginRight: "20px" }} />
                  Loading...
                </h2>:<FormPage form={apidata} id={id} />}
          </>
        );
        break;
      case 2:
        return (
          <>
          {fetching? <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90%",
                    width: "100%",
                  }}
                >
                  <CircularProgress style={{ marginRight: "20px" }} />
                  Loading...
                </h2>:
            <PromoPage promo={apidata} id={id} />}
          </>
        );
        break;
      case 3:
        return (
          <>Error Please check your id</>
        );
        break;
    }
  };

  const getQuestionInfo = async(id)=>
  {
    console.log('getquestioninfo',id)
    axios.get(`${api}/survey/${id}`)
    .then((response)=>{
      if(response.status === 200 && response.data)
      { 
        console.log(response.data)
        setApidata(response.data);
        setStep(1);
        setFetching(false)
      }
    })
    .catch((error)=>{
      console.log(error)
      setStep(3)
      setFetching(false)
    })
  }
  const getPromoInfo = (id)=>
  {
    console.log('getpromoinfo',id)
    axios.get(`${api}/socials/${id}`)
    .then((response)=>{
      if(response.status === 200 && response.data)
      { 
        console.log(response.data)
        setApidata(response.data)
        setFetching(false)
        setStep(2);
      }
    })
    .catch((error)=>{
      console.log(error)
      setStep(3)
      setFetching(false)
    })
  }

  useEffect(() => {
    if (id && routeStep) {
      if (routeStep === "2") {
        getQuestionInfo(id)
      } else if (routeStep === "3") {
        getPromoInfo(id)
      }
    }
    else
    {
      setStep(3)
    }
  }, []);

  if (id) {
    return (
      <div className="dashboard">
        <div className="dashboard-content">
          <>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={openSnackbar}
              autoHideDuration={2000}
              onClose={handleClose}
              message={msg}
              action={
                <>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
            <TopBar editmode={true} search={false} />

            <h1>Control Dashboard</h1>
            {stepFunction()}
            <Modal
              open={completedModal}
              onClose={() => {
                setCompletedModal(false);
              }}
            >
              {errMsg && (
                <>
                  <ErrorContainer>
                    <Close
                      onClick={() => {
                        console.log(paymentId);
                        setPaymentId(null);
                        setError("");
                        window.location.search = "";
                      }}
                    />
                    {errMsg}
                  </ErrorContainer>
                </>
              )}
            </Modal>
            <Modal open={guidlines} handleClose={() => setGuidlines(false)}>
              <GuideLines handleClose={() => setGuidlines(false)} />
            </Modal>
          </>
        </div>
      </div>
    );
  }
  return null;
};

export default EditPage;
