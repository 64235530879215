import React from 'react';
import { Navbar } from '../components/_index';
import { Content, SubTitle, Title } from './_styles';

const Terms = () => {
  return (
    <>
      <div>
        <Navbar />
        <Title>Terms & Conditions</Title>
        <Content>
          <p style={{ fontStyle: 'italic' }}>
          This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, Privacy Policy and Terms & Conditions for access or usage of www.etark.in website and ETark application for mobile and handheld devices.









         </p>
          <br />
          
          <p>
        
These terms & conditions (the "Terms & Conditions") govern your use of our website www.etark.in (the "Website") and our "ETark" application for mobile and handheld devices (the "App"). The Website and the App are jointly referred to as the "Platform". Please read these Terms & Conditions carefully before you use the services. If you do not agree to these Terms & Conditions, you may not use the services on the Platform, and we request you to uninstall the App. By installing, downloading or even merely using the Platform, you shall be contracting with ETark and you signify your acceptance to these Terms & Conditions and other ETark policies (including but not limited to the Return, Refund & Cancellation Policy and Privacy) as posted on the Platform and amended from time to time, which takes effect on the date on which you download, install or use the Platform, and create a legally binding arrangement to abide by the same.
The Platform is owned and operated by ETark Technology Private Limited, a private limited company incorporated under the Companies Act, 2013 and having its registered office at No.13, K.B. Sarani, Dum Dum, Kolkata – 700080, West Bengal, India. For the purpose of these Terms & Conditions, wherever the context so requires, "you", “user”, or “User” shall mean any natural or legal person who shall transact on the Platform by providing registration data while registering on the Platform as a registered user using any computer systems. The terms "ETark", "we", "us" or "our" shall mean ETark Technology Private Limited.
ETark enables analysis of consumer complaint against any product/service replete with suggestions on the various options to resolve that complaint and facilitates Offers ("Offers") of various products/services from its partner companies on its Platform for use by ETark Users ("Platform Services"). ETark also enables various 
Resolution Platforms (“Resolution Platforms”) listed on ETark including but not limited to Consumer Commission Online-Application Portal or any other platform by the Department of Consumer Affairs, Govt. Of India, Customer Support Link(s) or any other link(s) of the company against which the complaint was filed on ETark and various Legal Aid listed on ETark including but not limited to Online Dispute Resolution Platforms, Consumer Advocacy Groups, Law firms, Legal Services companies, Lawyers or any other Services listed on ETark’s platform to be availed by the user to resolve his complaint (“Resolution Service”).
Resolution Service by any Resolution Partner needs to be validated by the user and should be availed at one’s own risk including fee for the services of the Resolution Platform or any contract/agreement with the  Resolution Platform. 
The User could also download ETark Reports for which the user could be required to pay ETark a service fee (“Service Fee”), the amount of which could vary from time to time at our discretion without any prior notice to the user.


          </p>
          <br />
         
          <p>
          Amendments
These Terms & Conditions are subject to modifications. We reserve the right to modify or change these Terms & Conditions and other ETark policies at any time by posting modified documents on the Platform or by notifying you of the same for your perusal. You shall be liable to update yourself of such changes, if any, by accessing the same. You shall, at all times, be responsible for regularly reviewing the Terms & Conditions and the other ETark policies and note the changes made on the Platform. Your continued usage of the Services after any change is posted constitutes your acceptance of the amended Terms & Conditions and other ETark policies. As long as you comply with these Terms & Conditions, ETark grants you a personal, non-exclusive, non-transferable, limited privilege to access, enter, and use the Platform. By accepting these Terms & Conditions, you also accept and agree to be bound by the other terms and conditions and ETark policies (including but not limited to Return, Refund & Cancellation Policy, Privacy Policy and Notice and Take Down Policy) as may be posted on the Platform from time to time.

Use of Platform and Services
All commercial/contractual terms are offered by and agreed to between Users and Partner Companies alone with respect to Offers being offered by the Partner Companies. The commercial/contractual terms include without limitation price, applicable taxes, shipping costs, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to products and services. ETark does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such commercial/contractual terms between the Users and Partner Companies. ETark may, however, offer support services to Partner Companies in respect to Offer fulfilment, mode of payment, payment collection, call centre support and other ancillary services, pursuant to independent contracts executed by ETark with the Partner Companies. The Offers offered by the Partner Company are determined by the Partner Company itself and ETark has no role to play in such determination of price in any way whatsoever.

          </p>
          <br />
       
          <p>
          ETark does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc.) of any of the Partner Company or Resolution Platform. You are advised to independently verify the bona fides of any particular Partner Company that you choose to deal with on the Platform and use your best judgment on that behalf. All Partner Company offers and third-party offers are subject to respective party terms and conditions. ETark takes no responsibility for such offers.
ETark neither makes any representation or warranty as to specifics (such as quality, value, salability, etc.) of the Offers proposed to be  offered on the Platform nor does implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform. ETark accepts no liability for any errors or omissions, whether on behalf of itself or third parties.
ETark does not make any representation or warranty with respect to any aspect of the services being provided by the Resolution Platforms through the Platform including but not limited to registering the complaints, charging fee for the services, providing legal advice and Task completion services to the Users.
ETark is not responsible for any non-performance or breach of any contract entered into between Resolution Partners and users on the Platform. ETark cannot and does not guarantee that the concerned users, Partner Companies and Resolution Platforms will perform any transaction concluded on the Platform. ETark is not responsible for unsatisfactory or non-performance of services or damages or delays as a result of products which are out of stock, unavailable or back ordered.
ETark is operating an online marketplace and assumes the role of facilitator, and does not at any point of time during any transaction between User and Partner Company and/or User and Resolution Platform on our Platform come into or take possession of any of the products or services offered by Partner Company or Resolution Platform. At no time shall ETark hold any right, title or interest over the products nor shall ETark have any obligations or liabilities in respect of such contract entered into between User and Partner Company and/or User and Resolution Platform.
ETark is only providing a platform for communication and it is agreed that the contract for sale of any of the products or services save ETark’s Complaint Analysis service shall be a strictly bipartite contract between the Partner Company or Resolution Platform and the user. In case of complaints from the User pertaining to efficacy, quality, or any other such issues, ETark shall notify the same to the Partner Company and shall strive to redirect the User to the customer care of the Partner Company. The Partner Company shall be liable for redressing any issues related to the offers provided by  the respective Partner company. In the event you raise any complaint on any Partner Company’s portal accessed using our Platform, we shall strive to assist you to the best of our abilities by providing relevant information to you, such as details of the Partner Company and the specific Order to which the complaint relates, to enable satisfactory resolution of the complaint.
Similar to the above, ETark is only providing a platform for communication with Resolution Platform(s) and does not provide any Task completion services with respect to the Complaints placed by Users on the Platform as it is merely facilitating User’s complaint resolution by connecting the Users with the Resolution Platforms through our Platform. In case of complaints by the Users for deficiency or lapse in the delivery services or Task completion services provided by Resolution Platform, ETark shall strive to the best of its abilities to communicate the same to the Resolution Platform but does not guarantee the same.
Please note that there could be risks in dealing with underage persons or people acting under false pretence.

          </p>
          <br />
          <p>ETark - Use of the Website and Apps (Android and iOS)
You agree, undertake and confirm that your use of Platform shall be strictly governed by the following binding principles:
You shall not host, display, upload, download, modify, publish, transmit, update or share any information which:
belongs to another person and which you do not have any right to;
is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, slanderous, criminally inciting or invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;
is misleading or misrepresentative in any way;
is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;
harasses or advocates harassment of another person;
involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";
promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;
infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;
promotes an illegal or unauthorized copy of another person's copyrighted work (see "copyright complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;
contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);
provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;
provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;
contains video, photographs, or images of another person (with a minor or an adult);
tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;
engages in commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of products related to the Platform. Throughout these Terms & Conditions, ETark's prior written consent means a communication coming from ETark's Legal Department, specifically in response to your request, and expressly addressing and allowing the activity or conduct for which you seek authorization;
solicits gambling or engages in any gambling activity which is or could be construed as being illegal;
interferes with another user's use and enjoyment of the Platform or any third party's user and enjoyment of similar services;
refers to any website or URL that, in our sole discretion, contains material that is inappropriate for the Platform or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms & Conditions;
harm minors in any way;
infringes any patent, trademark, copyright or other intellectual property rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;
violates any law for the time being in force;
deceives or misleads the addressee/users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
impersonate another person;
contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;
threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any criminal offence or prevents investigation of any offence or is insulting any other nation;
is false, inaccurate or misleading;
directly or indirectly, offers, attempts to offer, trades or attempts to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force; or
creates liability for us or causes us to lose (in whole or in part) the services of our internet service provider or other suppliers.
You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Platform. We reserve our right to prohibit any such activity.
You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to any server, computer, network, or to any of the services offered on or through the Platform, by hacking, "password mining" or any other illegitimate means.
You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other user of or visitor to Platform, or any other User, including any account on the Platform not owned by you, to its source, or exploit the Platform or any service or information made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Platform.
You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about us or the brand name or aname used by us including the name 'ETark', or otherwise engage in any conduct or action that might tarnish the image or reputation, of ETark or Partner Company or Resolution Platform on our platform or otherwise tarnish or dilute any ETark's trade or service marks, trade name and/or goodwill associated with such trade or service marks, as may be owned or used by us. You agree that you will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or ETark's systems or networks, or any systems or networks connected to ETark.
You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being conducted on the Platform, or with any other person's use of the Platform.
You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to us on or through the Platform or any service offered on or through the Platform. You may not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.
You may not use the Platform or any content on the Platform for any purpose that is unlawful or prohibited by these Terms & Conditions, or to solicit the performance of any illegal activity or other activity that infringes the rights of ETark and/or others.
You shall at all times ensure full compliance with the applicable provisions, as amended from time to time, of (a) the Information Technology Act, 2000 and the rules thereunder; (b) all applicable domestic laws, rules and regulations (including the provisions of any applicable exchange control laws or regulations in force); and (c) international laws, foreign exchange laws, statutes, ordinances and regulations (including, but not limited to sales tax/VAT, income tax, octroi, service tax, central excise, custom duty, local levies) regarding your use of our service and your listing, purchase, solicitation of offers to purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.
In order to allow us to use the information supplied by you, without violating your rights or any laws, you agree to grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights you have in your Information, in any media now known or not currently known, with respect to your Information. We will only use your information in accordance with these Terms & Conditions and Privacy Policy applicable to use of the Platform.
From time to time, you shall be responsible for providing information relating to the products or services proposed to be sold by you. In this connection, you undertake that all such information shall be accurate in all respects. You shall not exaggerate or overemphasize the attributes of such products or services so as to mislead other users in any manner.
You shall not engage in advertising to, or solicitation of, other users of the Platform to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other users via the Platform. It shall be a violation of these Terms & Conditions to use any information obtained from the Platform in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than us without our prior explicit consent. In order to protect our users from such advertising or solicitation, we reserve the right to restrict the number of messages or emails which a user may send to other users in any 24-hour period which we deem appropriate in its sole discretion. You understand that we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Platform) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and you hereby expressly authorize us to) disclose any information about you to law enforcement or other government officials, as we, in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.
We reserve the right, but has no obligation, to monitor the materials posted on the Platform. ETark shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms & Conditions. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content posted does not necessarily reflect ETark views. In no event shall ETark assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from use of Content and/or appearance of Content on the Platform. You hereby represent and warrant that you have all necessary rights in and to all Content which you provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.
Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Platform, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Platform.
It is possible that other users (including unauthorized users or 'hackers') may post or transmit offensive or obscene materials on the Platform and that you may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about you due to your use of the Platform, and that the recipient may use such information to harass or injure you. We do not approve of such unauthorized uses, but by using the Platform You acknowledge and agree that we are not responsible for the use of any personal information that you publicly disclose or share with others on the Platform. Please carefully select the type of information that you publicly disclose or share with others on the Platform.
ETark shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services), hacking, pen testing attempts without our prior consent or a mutual legal agreement.
</p>
              <br />
              <SubTitle pl="none"> Account Registration </SubTitle>
          <p>
         
You may access the Platform by registering to create an account (" ETark Account") and become a member (" Membership"); or (c) you can also register to join by logging into your account with certain third party social networking sites ("SNS") (including, but not limited to, Facebook); each such account, a " Third Party Account", via our Platform, as described below. The Membership is limited for the purpose and are subject to the terms, and strictly not transferable. As part of the functionality of the Platform services, you may link your ETark Account with Third Party Accounts, by either:
providing your Third Party Account login information to us through the Platform; or
allowing us to access your Third Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third Party Account.
You represent that you are entitled to disclose your Third Party Account login information to us and/or grant us access to your Third Party Account (including, but not limited to, for use for the purposes described herein), without breach by you of any of the terms and conditions that govern your use of the applicable Third Party Account and without obligating us to pay any fees or making us subject to any usage limitations imposed by such third party service providers.
By granting us access to any Third Party Accounts, you understand that we will access, make available and store (if applicable) any content or information that you have provided to and stored in your Third Party Account (" SNS Content") so that it is available on and through the Platform via your ETark Account.
Unless otherwise specified in these Terms & Conditions, all SNS Content, if any, will be considered to be your content for all purposes of these Terms & Conditions.
Depending on the Third Party Accounts, you choose, and subject to the privacy settings that you have set in such Third Party Accounts, personally identifiable information that you post to your Third Party Accounts will be available on and through your ETark Account on the Platform.
Please note that if a Third Party Account or associated service becomes unavailable or our access to such Third Party Account is terminated by the third party service provider, then SNS Content will no longer be available on and through the Platform.
We will create your ETark Account for your use of the Platform services based upon the personal information you provide to us or that we obtain via SNS, as described above. You can only have one ETark Account and are not permitted to create multiple accounts. ETark reserves the right to suspend such multiple accounts without being liable for any compensation where you have created multiple accounts on the Platform.
You agree to provide accurate, current and complete information during the registration process and update such information to keep it accurate, current and complete.
We reserve the right to suspend or terminate your ETark Account and your access to the Services (i) if any information provided during the registration process or thereafter proves to be inaccurate, not current or incomplete; (ii) if it is believed that your actions may cause legal liability for you, other users or us; and/or (iii) if you are found to be non-compliant with the Terms & Conditions or other ETark policies.
You are responsible for safeguarding your password. You agree that you will not disclose your password to any third party and that you will take sole responsibility for any activities or actions under your ETark Account, whether or not you have authorized such activities or actions. You will immediately notify us of any unauthorized use of your ETark Account.
Goods and services purchased from the Platform are intended for your personal use and you represent that the same are not for resale or you are not acting as an agent for other parties.

              </p>
              <br/>
              <SubTitle pl="none">Complaint Analysis and Financial Terms</SubTitle>
              <p>
              


The Platform allows the Users to analyze complaints against any product or service instantly and  get a resolution of the same through our Resolution Platforms.
ETark does not own, sell, resell on its own such products/ services offered by the Resolution Platforms, and/or does not control the Resolution Platforms or the related services provided in connection thereof. User understands that any complaint resolution that he/she seeks on ETark shall be subject to the terms and conditions set out in these Terms & Conditions including, but not limited to, service availability by the Resolution Partner, requirement of appropriate proof of complaint such as Bill id, Order ID, Account ID or any other necessary ID required to prove that the user was the rightful owner of the product/service, and the associated complaint for which he/she is seeking for the resolution from our Resolution Partners. 


As a general rule, all details of our reports are accessible by the user instantly. 
However, upon User’s request for a detailed report of the complaint (“User Complaint Report” or “Report”) which he wants to refer to later on, user will be required to share certain information with us, including but not limited to User’s (i) first and last name (ii) mobile number; and (iii) email address. It shall be User’s sole responsibility to bring any incorrect details to our attention.
In addition to the foregoing, we may also contact you by phone and / or email to inform and confirm any change in the user’s Report, due to availability or unavailability or change in data or change in price of Report or any  technical challenge  from time to time. 


All payments made against the Orders or Services on the Platform by you shall be compulsorily in Indian Rupees acceptable in the Republic of India. The Platform will not facilitate transactions with respect to any other form of currency with respect to the Orders or Services made on Platform. You can pay by (i) credit card or debit card or net banking; (ii) any other RBI approved payment method at the time of booking an Order; or (iii) credit or debit card or cash at the time of delivery. You understand, accept and agree that the payment facility provided by ETark is neither a banking nor financial service but is merely a facilitator providing an electronic, automated online electronic payment, receiving payment on delivery, collection and remittance facility for the transactions on the Platform using the existing authorized banking infrastructure and credit card payment gateway networks. Further, by providing payment facility, ETark is neither acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.
Users acknowledge and agree that ETark acts as the Resolution Partner’s and User’s and/ or the Partner Company’s and the User’s payment agent for the limited purpose of accepting payments from Users, for a complaint resolution or availing an Offer, as the case may be. Upon your payment of amounts to us, which are due to the Resolution Partner or the Partner Company , your payment obligation to the Resolution Partner or the Partner Company for such amounts is completed, and we are responsible for remitting such amounts to the Resolution Partner or the Partner Company except those cases where the User is redirected to the Partner Company’s or the Resolution Platform’s website/App and is making the payment on the Partner Company’s platform directly for which ETark shall not be responsible for any remittance  of such  amount to the Partner Company or the Resolution Platform and the user would be directly responsible for his/her payment obligation to the Resolution Partner or the Partner Company. 

User agrees to pay for the total amount for the Offers placed on the Platform. ETark may collect the total amount in accordance with these Terms & Conditions and the pricing terms set forth in the applicable listing of offers by the Partner Company. Please note that we cannot control any amount that may be charged to User by his/her bank related to our collection of the total amount, and we disclaim all liability in this regard.
In connection with Offers by Partner Companies, user could be asked to provide customary billing information such as name, billing address and credit card information either to us or the Partner Company or our third party payment processor. User agrees to pay  for the Offers he/she wishes to avail on the Platform, in accordance with these Terms, using the methods described under clause VIII (6) above. User hereby authorizes the collection of such amounts by charging the credit card provided as part of requesting the booking, either directly by us or indirectly, via a third party online payment processor or by one of the payment methods described on the Platform. If User is directed to our third-party payment processor, he/she may be subject to terms and conditions governing use of that third party's service and that third party's personal information collection practices. Please review such terms and conditions and privacy policy before using the Platform services. Once the Order is confirmed you will receive a confirmation email summarizing the confirmed booking.
The final tax bill regarding any Offer purchase and any service from our Resolution Platforms will be issued by the Partner Company and the Resolution Platform respectively (if registered for tax purposes) to the User along with the Offer and the Resolution Service  and ETark could merely collect the payment on behalf of such Partner Company and Resolution Platforms. All applicable taxes and levies, the rates thereof and the manner of applicability of such taxes on the bill are being charged and determined by the Partner Company and Resolution Platforms. ETark holds no responsibility for the legal correctness/validity of the levy of such taxes. The sole responsibility for any legal issue arising on the taxes shall reside with the Partner Company and Resolution Platforms.
The prices reflected on the Platform, including packaging or handling charges, are determined solely by the Partner Company and the Resolution Partner as the case may be and are listed based on Partner Company and Resolution Platform information. Very rarely, prices may change at the time of making a payment due to the Partner Company or Resolution Platform changing the Prices without due intimation and such change of price are at the sole discretion of the Partner Company and the Resolution Platform attributing to various factors beyond control.
Disclaimer: Prices on any product(s) as reflected on the Platform may due to some technical issue, typographical error or product information supplied by Partner Company or the Resolution Platform  be incorrectly reflected and in such an event Partner Company or the Resolution Platform may cancel User’s payment.
The Partner Company and the Resolution Platform shall be solely responsible for any warranty/guarantee of the goods or services sold to the Users and in no event shall be the responsibility of ETark.
The transactions are bilateral between the Partner Company and User, and between Resolution Platform and user, therefore, ETark is not liable to charge or deposit any taxes applicable on such transactions. 
 

              </p>
              <br/>
              <SubTitle pl="none">Cancellations and Refunds</SubTitle>
              <p>
            



Please refer to the Cancellation and Refund Policy for cancellation and refunds terms in relation to usage of the Platform for availing Services.  



  </p>
              <br/>
              <SubTitle pl="none">No Endorsement</SubTitle>
              <p>
                  
              We do not endorse any Partner Company or any Resolution Platform. In addition, although these Terms & Conditions require you to provide accurate information, we do not attempt to confirm, and do not confirm if it is purported identity. We will not be responsible for any damage or harm resulting from your interactions with other Members.
By using the Services, you agree that any legal remedy or liability that you seek to obtain for actions or omissions of other Members or other third parties will be limited to a claim against the particular Members or other third parties who caused you harm and you agree not to attempt to impose liability on, or seek any legal remedy from us with respect to such actions or omissions.
           </p>
            <br/>
              <SubTitle pl="none">General:</SubTitle>
              <p>
  

Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Platform. Only individuals who are 18 years of age or older may use the Platform and avail Services. If you are under 18 years of age and you wish to download, install, access or use the Platform, your parents or legal guardian must acknowledge and agree to the Terms & Conditions and Privacy Policy. Should your parents or legal guardian fail to agree or acknowledge the Terms & Conditions and ETark policies, you shall immediately discontinue its use. ETark reserves the right to terminate your Membership and / or deny access to the platform if it is brought to ETark's notice that you are under the age of 18 years.
If you choose to use the Platform, it shall be your responsibility to treat your user identification code, password and any other piece of information that we may provide, as part of our security procedures, as confidential and not disclose the same to any person or entity other than us. We shall at times and at our sole discretion reserve the right to disable any user identification code or password if you have failed to comply with any of the provisions of these Terms & Conditions.
The Offers by Partner Companies and Resolution Services by our Resolution Platforms may be offline and could depend on the geographical location in India and as such we have complied with applicable laws of India in making the Platform and its content available to you. In the event the Platform is accessed from outside India or outside our delivery zones, it shall be entirely at your risk. We make no representation that the Platform and its contents are available or otherwise suitable for use outside India. If you choose to access or use the Platform from or in locations outside India, you do so on your own and shall be responsible for the consequences and ensuring compliance of applicable laws, regulations, byelaws, licenses, registrations, permits, authorisations, rules and guidelines.
You shall at all times be responsible for the use of the Services through your computer or mobile device and for bringing these Terms & Conditions and ETark policies to the attention of all such persons accessing the Platform on your computer or mobile device.
You understand and agree that the use of the Services does not include the provision of a computer or mobile device or other necessary equipment to access it. You also understand and acknowledge that the use of the Platform requires internet connectivity and telecommunication links. You shall bear the costs incurred to access and use the Platform and avail the Services, and we shall not, under any circumstances whatsoever, be responsible or liable for such costs.
You agree and grant permission to ETark to receive promotional SMS and e-mails from ETark or allied partners. In case you wish to opt out of receiving promotional SMS or email please send a mail to contact@etark.in
By using the Platform you represent and warrant that:
All registration information you submit is truthful, lawful and accurate and that you agree to maintain the accuracy of such information.
Your use of the Platform shall be solely for your personal use and you shall not authorize others to use your account, including your profile or email address and that you are solely responsible for all content published or displayed through your account, including any email messages, and your interactions with other users and you shall abide by all applicable local, state, national and foreign laws, treaties and regulations, including those related to data privacy, international communications and the transmission of technical or personal data.
You will not submit, post, upload, distribute, or otherwise make available or transmit any content that: (a) is defamatory, abusive, harassing, insulting, threatening, or that could be deemed to be stalking or constitute an invasion of a right of privacy of another person; (b) is bigoted, hateful, or racially or otherwise offensive; (c) is violent, vulgar, obscene, pornographic or otherwise sexually explicit; (d) is illegal or encourages or advocates illegal activity or the discussion of illegal activities with the intent to commit them.
All necessary licenses, consents, permissions and rights are owned by you and there is no need for any payment or permission or authorization required from any other party or entity to use, distribute or otherwise exploit in all manners permitted by these Terms & Conditions and Privacy Policy, all trademarks, copyrights, patents, trade secrets, privacy and publicity rights and / or other proprietary rights contained in any content that you submit, post, upload, distribute or otherwise transmit or make available.
You will not (a) use any services provided by the Platform for commercial purposes of any kind, or (b) advertise or sell any products, services or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind. In the event you want to advertise your product or service contact contact@ETark.in.
You will not use the Platform in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.
You will not post, submit, upload, distribute, or otherwise transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise interfere with any person or entity's use or enjoyment of the Platform.
You will not use another person's username, password or other account information, or another person's name, likeness, voice, image or photograph or impersonate any person or entity or misrepresent your identity or affiliation with any person or entity.
You will not engage in any form of antisocial, disrupting, or destructive acts, including "flaming," "spamming," "flooding," "trolling," and "griefing" as those terms are commonly understood and used on the Internet.
You will not delete or modify any content of the Platform, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or have express permission to modify.
You will not post or contribute any information or data that may be obscene, indecent, pornographic, vulgar, profane, racist, sexist, discriminatory, offensive, derogatory, harmful, harassing, threatening, embarrassing, malicious, abusive, hateful, menacing, defamatory, untrue or political or contrary to our interest.
You shall not access the Platform without authority or use the Platform in a manner that damages, interferes or disrupts:
any part of the Platform or the Platform software; or
any equipment or any network on which the Platform is stored or any equipment of any third party
You release and fully indemnify ETark and/or any of its officers and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Platform and specifically waive any claims that you may have in this behalf under any applicable laws of India. Notwithstanding its reasonable efforts in this behalf, ETark cannot take responsibility or control the information provided by other Users which is made available on the Platform. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Platform.
Access to the Platform, Accuracy and security
We endeavour to make the Services available 24*7, everyday throughout the year. However, we do not represent that access to the Platform will be uninterrupted, timely, error free, free of viruses or other harmful components or that such defects will be corrected.
We do not warrant that the Platform will be compatible with all hardware and software which you may use. We shall not be liable for damage to, or viruses or other code that may affect, any equipment (including but not limited to your mobile device), software, data or other property as a result of your download, installation, access to or use of the Platform or your obtaining any material from, or as a result of using, the Platform. We shall also not be liable for the actions of third parties.
We do not represent or warranty that the information available on the Platform will be correct, accurate or otherwise reliable.
We reserve the right to suspend or withdraw access to the Platform to you personally, or to all users temporarily or permanently at any time without notice. We may any time at our sole discretion reinstate suspended users. A suspended User may not register or attempt to register with us or use the Platform in any manner whatsoever until such time that such user is reinstated by us.
Relationship with operators if the Platform is accessed on mobile devices
In the event the Platform is accessed on a mobile device, it is not associated, affiliated, sponsored, endorsed or in any way linked to any platform operator, including, without limitation, Apple, Google, Android or RIM Blackberry (each being an " Operator").
Your download, installation, access to or use of the Platform is also bound by the terms and conditions of the Operator.
You and we acknowledge that these Terms & Conditions are concluded between you and ETark only, and not with an Operator, and we, not those Operators, are solely responsible for the Platform and the content thereof to the extent specified in these Terms & Conditions.
The license granted to you for the Platform is limited to a non-transferable license to use the Platform on a mobile device that you own or control and as permitted by these Terms & Conditions.
We are solely responsible for providing any maintenance and support services with respect to the Platform as required under applicable law. You and we acknowledge that an Operator has no obligation whatsoever to furnish any maintenance and support services with respect to the Platform.
You and we acknowledge that we, not the relevant Operator, are responsible for addressing any claims of you or any third party relating to the Platform or your possession and/or use of the Platform, including, but not limited to: (i) any claim that the Platform fails to conform to any applicable legal or regulatory requirement; and (ii) claims arising under consumer protection or similar legislation.
You and we acknowledge that, in the event of any third party claim that the Platform or your possession and use of the Platform infringes that third party's intellectual property rights, we, not the relevant Operator, will be solely responsible for the investigation, defence, settlement and discharge of any such intellectual property infringement claim.
You must comply with any applicable third party terms of agreement when using the Platform (e.g. you must ensure that your use of the Platform is not in violation of your mobile device agreement or any wireless data service agreement).
You and we acknowledge and agree that the relevant Operator, and that Operator's subsidiaries, are third party beneficiaries of these Terms & Conditions, and that, upon your acceptance of these Terms & Conditions, that Operator will have the right (and will be deemed to have accepted the right) to enforce these Terms & Conditions against you as a third party beneficiary thereof.
Disclaimers
THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND FEATURES MAY NOT BE FULLY OPERATIONAL.
DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS, OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY CASE.
WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.
YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY REGARDING ANY THIRD PARTY'S SERVICES NOR WILL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD PARTY'S / MERCHANT’S/ PDP’S SERVICES.
ETark DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY VIOLATION OF ANY APPLICABLE LAWS INCLUDING THE LAW APPLICABLE TO PRODUCTS AND SERVICES OFFERED BY THE MERCHANT OR PDP.
WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO PROVIDE ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE WITH THE UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE ON SUCH INFORMATION.
THE INFORMATION PROVIDED HEREUNDER IS PROVIDED "AS IS". WE AND / OR OUR EMPLOYEES MAKE NO WARRANTY OR REPRESENTATION REGARDING THE TIMELINESS, CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR COMPLETENESS OF ANY INFORMATION OR DATA FURNISHED HEREUNDER OR THAT THE INFORMATION OR DATA PROVIDED HEREUNDER MAY BE RELIED UPON. MULTIPLE RESPONSES MAY USUALLY BE MADE AVAILABLE FROM DIFFERENT SOURCES AND IT IS LEFT TO THE JUDGEMENT OF USERS BASED ON THEIR SPECIFIC CIRCUMSTANCES TO USE, ADAPT, MODIFY OR ALTER SUGGESTIONS OR USE THEM IN CONJUNCTION WITH ANY OTHER SOURCES THEY MAY HAVE, THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS, BUSINESS ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND EMPLOYEES FROM ANY KIND OF PROFESSIONAL LIABILITY.
WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR INJURY ARISING OUT OF OR RELATING TO THE INFORMATION PROVIDED ON THE PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT CONTAINED HEREIN.
IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL DEATH, AND DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION), RESULTING FROM ANY SERVICES PROVIDED BY ANY THIRD PARTY OR MERCHANT ACCESSED THROUGH THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
Intellectual property
We are either the owner of intellectual property rights or have the non-exclusive, worldwide, perpetual, irrevocable, royalty free, sub-licensable (through multiple tiers) right to exercise the intellectual property, in the Platform, and in the material published on it including but not limited to user interface, layout format, Complaint Analysis process flow and any content thereof.
You recognize that ETark is the registered owner of the word mark ‘ETark’ and the logo  including but not limited to its variants (IPR) and shall not directly or indirectly, attack or assist another in attacking the validity of, or ETark’s or its affiliates proprietary rights in, the licensed marks or any registrations thereof, or file any applications for the registration of the licensed marks or any names or logos derived from or confusingly similar to the licensed marks, any variation thereof, or any translation or transliteration thereof in another language, in respect of any products/services and in any territory throughout the world. If you become aware or acquire knowledge of any infringement of IPR you shall report the same at contact@etark.in with all relevant information.
You may print off one copy, and may download extracts, of any page(s) from the Platform for your personal reference and you may draw the attention of others within your organisation to material available on the Platform.
You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
You must not use any part of the materials on the Platform for commercial purposes without obtaining a licence to do so from us or our licensors.
If you print off, copy or download any part of the Platform in breach of these Terms & Conditions, your right to use the Platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
Treatment of information provided by you
We process information provided by you to us in accordance with our Privacy Policy.
Third Party Content
We cannot and will not assure that other users are or will be complying with the foregoing rules or any other provisions of these Terms & Conditions, and, as between you and us, you hereby assume all risk of harm or injury resulting from any such lack of compliance.
You acknowledge that when you access a link that leaves the Platform, the site you will enter into is not controlled by us and different Terms & Conditions and Privacy Policy may apply. By assessing links to other sites, you acknowledge that we are not responsible for those sites. We reserve the right to disable links to and / or from third-party sites to the Platform, although we are under no obligation to do so.
Severability
If any of these Terms & Conditions should be determined to be illegal, invalid or otherwise unenforceable by reason of the laws of any state or country in which these Terms & Conditions are intended to be effective, then to the extent and within the jurisdiction where that term is illegal, invalid or unenforceable, it shall be severed and deleted and the remaining Terms & Conditions shall survive, remain in full force and effect and continue to be binding and enforceable.
Non-assignment
You shall not assign or transfer or purport to assign or transfer the contract between you and us to any other person.
Governing law and dispute resolution
These Terms & Conditions are governed by the laws of India. Any action, suit, or other legal proceeding, which is commenced to resolve any matter arising under or relating to this Platform, shall be subject to the jurisdiction of the courts at Kolkata, India.
IP Notice and Take Down Policy
ETark has put in place IP Notice and Take Down Policy (" Take Down Policy") so that intellectual property owners could easily report listings that infringe their right to ensure that infringing products are removed from the site, as they erode User and good Seller trust.
Only the intellectual property rights owner can report potentially infringing products or listings through Take Down Policy by way of Notice of infringement in the specified format. If you are not the intellectual property rights owner, you can still help by getting in touch with the rights owner and encouraging them to contact us.        
Note: ETark does not and cannot verify that Partner Companies and Resolution Platforms have the right or ability to sell or distribute their listed products or services. However, ETark is committed to ensure that item listings do not infringe upon intellectual property rights of third parties once an authorized representative of the rights owner properly reports them to ETark.
             </p>
           
        </Content>
      </div>
    </>
  );
};

export default Terms;
