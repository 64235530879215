import React, { useState } from 'react';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { Button, Checkbox, CircularProgress } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AuthSidebar from './AuthSidebar';
import {
  AuthContainer,
  AuthWrapper,
  Error,
  ForgetModal,
  SignInBtn,
  SigninWrapper,
  StyledTextInput,
  Title,
} from './styles';
import { validateEmail, validatePhone, validateUserId } from '../../helpers/validations';
import { Modal } from '../../helpers/index';

const api = process.env.REACT_APP_API;

const AdminSignin = () => {
  const [loginState, setLoginState] = useState('phone');
  const [phone, setPhone] = useState('');
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepMeSignedIn, setKeepMeSignedIn] = useState(false);
  const [visible, setVisible] = useState(true);

  const [forgetModal, setForgetModal] = useState(false);
  const [panel, setPanel] = useState(1);

  const [resetEmail, setResetEmail] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const handlePhone = (e) => {
    // if (isNaN(e.target.value)) return;
    setPhone(e.target.value);
  };

  const handleDisable =
    (phone.length === 0) ||
    validatePhone(phone)||
    password.length === 0;

  const handleCheck = (event) => {
    setKeepMeSignedIn(event.target.checked);
  };

  const signin = async () => {
    setLoading(true);
      const data = {
        "userid":phone,
        "password":password
        }
    try {
      const res = await axios.post(`${api}/admin/login`, data);
      console.log(res);
      if (res.status === 200) {
        setLoading(false);
        setError('');
        localStorage.setItem('jwt', res.data.token);
        localStorage.setItem('adminStatus', true);
        localStorage.setItem('userId', res.data.account._id);
        window.location.reload();
      }
    } catch (error) {
      console.log(error?.response);
      setError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const sendResetToken = async () => {
    try {
      setResetLoading(true);
      const data = {
        email: resetEmail,
      };
      const res = await axios.post(`${api}/socialusers/sendResetToken`, data);
      if (res.status === 200) {
        setResetLoading(false);
        setPanel(2);
      }
    } catch (error) {
      console.log(error.response);
      setResetLoading(false);
    }
  };

  let token = localStorage.getItem('jwt');

  return (
    <>
      {token && <Redirect to="/admin/dashboard" />}
      <AuthWrapper>
        <AuthSidebar />
        <AuthContainer>
          <div className="upper-helper-text">
            Return to 
            <Link to="/">
              <span> &nbsp; Home </span>
            </Link>
          </div>
          <SigninWrapper>
            <h1>Sign In to Social As Admin</h1>

            <br/>
            <br/>

            {error && (
              <Error>
                <InfoOutlinedIcon /> {error}
              </Error>
            )}

           
                <Title>User Id</Title>
                <StyledTextInput
                  required
                  variant="outlined"
                  type="text"
                  value={phone}
                  onChange={handlePhone}
                  helperText={
                    !validateUserId(phone) && 'Enter Your userId'
                  }
                />
           
            <div style={{ position: 'relative' }}>
              <Title>Password</Title>
              <StyledTextInput
                required
                type={visible ? 'password' : 'text'}
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {visible ? (
                <Visibility
                  onClick={() => setVisible(false)}
                  className="signin-eye"
                />
              ) : (
                <VisibilityOff
                  onClick={() => setVisible(true)}
                  className="signin-eye"
                />
              )}
            </div>
            <div className="bottom-section">
              <div>
                <Checkbox
                  color="primary"
                  size="small"
                  checked={keepMeSignedIn}
                  onChange={handleCheck}
                />
                <p>Keep me Signed In</p>
              </div>
              <p onClick={() => setForgetModal(true)}>Forget Password ? </p>
            </div>
            {loading ? (
              <CircularProgress size={30} style={{ margin: '20px 0' }} />
            ) : (
              <SignInBtn
                disabled={handleDisable}
                style={{ backgroundColor: handleDisable && 'lightgray' }}
                onClick={() => signin()}
              >
                Sign in
              </SignInBtn>
            )}
          </SigninWrapper>
        </AuthContainer>
      </AuthWrapper>
      <Modal
        open={forgetModal}
        handleClose={() => {
          setForgetModal(false);
          setResetLoading(false);
        }}
      >
        <ForgetModal>
          <CloseIcon onClick={() => setForgetModal(false)} />
          {panel === 1 ? (
            <div className="forgot-1">
              <StyledTextInput
                maxWidth="650px"
                className="refbtn"
                label="Enter your email address"
                variant="outlined"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                helperText={
                  validateEmail(resetEmail) != true && 'Enter a valid Email'
                }
              />
              {resetLoading ? (
                <CircularProgress style={{ marginTop: 23, marginLeft: 5 }} />
              ) : (
                <SignInBtn onClick={sendResetToken}>Submit</SignInBtn>
              )}
            </div>
          ) : (
            <>
              <div className="forgot-2">
                <img src="/assets/images/check.png" />
                <p>
                  A mail has been sent to your email address along with a link
                  to change password. Please check your inbox.
                </p>
              </div>
            </>
          )}
        </ForgetModal>
      </Modal>
    </>
  );
};

export default AdminSignin;
