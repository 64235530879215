import React from 'react';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { HelpContainer, HelpText } from './_styles';

const Help = ({ handleClose }) => {
  return (
    <HelpContainer>
      <ClearSharpIcon onClick={handleClose} />
      <h1>Help & Documentation</h1>
      <HelpText>
        <span>1.</span>
        <div>
          <p>Fill in the details</p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/1.png" alt="" />
      </div>
      <HelpText>
        <span>2.</span>
        <div>
          <p>
            {' '}
            Check the expected output. Your actual clicks will be equal to or
            more than the expected clicks. In case you get less than the
            expected clicks, we'll refund you the amount for the remaining
            clicks.
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/2.png" alt="" />
      </div>
      <HelpText>
        <span>3.</span>
        <div>
          <p>
            If you are satisfied with the expected output, click on the Pay to
            Publish button and your Promo gets sent for publishing on the Social
            Chat App.
          </p>
        </div>
      </HelpText>
      <div>
        <img src="assets/images/help/3.png" alt="" />
      </div>
      <HelpText>
        <span>4.</span>
        <div>
          <p>
            We'll verify your Promo as per our Promotion Guidelines within 45
            min post which we'll publish your Promo.
          </p>
        </div>
      </HelpText>
      <div>
        <img className="img4" src="assets/images/help/4.png" alt="" />
      </div>
    </HelpContainer>
  );
};

export default Help;
