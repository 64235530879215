import React, { useEffect, useState } from 'react';
import AuthSidebar from './AuthSidebar';
import axios from 'axios';
import {
  AuthContainer,
  AuthWrapper,
  ResetContainer,
  SignInBtn,
  StyledTextInput,
  Title,
} from './styles';
import { useHistory } from 'react-router';

const api = process.env.REACT_APP_API;

const Reset = () => {
  const history = useHistory();
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');
  const [params, setParams] = useState();

  const disabled = p1.length < 6 || p1 !== p2;

  useEffect(() => {
    const Params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    setParams(Params);
  }, []);

  //   const getUser = async () => {
  //     try {
  //       const res = await axios.get(`${api}/socialusers/`);
  //       console.log('user Info', res);
  //     } catch (error) {
  //       console.log(error.response);
  //     }
  //   };

  const verifyToken = async () => {
    try {
      const data = {
        id: params.id,
        resetPasswordToken: params.token,
        newpass: p1,
      };

      const res = await axios.post(`${api}/socialusers/verifyResetToken`, data);
      console.log('verificaiton res', res);

      if (res.status === 200) {
        history.push('/signin');
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <div>
      <AuthWrapper>
        <AuthSidebar />
        <AuthContainer>
          <ResetContainer>
            <h1>Reset Password</h1>
            <div>
              <Title>New Password</Title>
              <StyledTextInput
                value={p1}
                onChange={(e) => setP1(e.target.value)}
                variant="outlined"
                helperText={
                  p1.length !== 0 &&
                  p1.length < 6 &&
                  'Password must be 6 characters long'
                }
              />
              <Title>Confirm Password</Title>
              <StyledTextInput
                value={p2}
                onChange={(e) => setP2(e.target.value)}
                variant="outlined"
                helperText={
                  p2.length !== 0 && p1 !== p2 && 'Passwords do not match'
                }
              />
            </div>
            <SignInBtn
              disabled={disabled}
              style={{
                marginTop: 30,
                backgroundColor: disabled && 'lightgray',
              }}
              onClick={verifyToken}
            >
              Change Password
            </SignInBtn>
          </ResetContainer>
        </AuthContainer>
      </AuthWrapper>
    </div>
  );
};

export default Reset;
