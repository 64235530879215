import { Avatar } from '@material-ui/core';
import React from 'react';
import './InAppPreview.scss';

const InAppPreview = ({ previewLogo, title, description }) => {
  return (
    <>
      <div className="InAppPreview">
        <h3 className="title">In App Preview</h3>
        <div className="Preview">
          <div className="Preview-content">
            {previewLogo ? (
              <div className="Preview-logo">
                <Avatar src={previewLogo} alt="" />
              </div>
            ) : (
              <div className="Preview-logo-sample">LOGO</div>
            )}
            <div>
              <h5>{title !== '' ? title : 'Title'}</h5>
              <p>{description ? description : 'Short Description'}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InAppPreview;
