import * as React from "react";
import { Avatar, IconButton } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import HelpIcon from "@mui/icons-material/Help";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Link } from "react-router-dom";
import { Modal } from "../../../helpers";
import Help from "../../../pages/Help";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: '#000',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
const TopBar = ({ search, setStep, step, searchSurvey, editmode,searchtype }) => {
  const [openHelp, setOpenHelp] = React.useState(false);
  const history = useHistory();

  const handleLogout = () => {
    try {
      localStorage.removeItem("jwt");
      localStorage.removeItem("userId");
      localStorage.removeItem("adminStatus");
      history.push("/")
    } 
    catch (e) {
      console.log('Error in logging out',e);
    }
  };
  return (
    <>
      <div className="headbar">
        {search && (
          <div className="search_box">
            <SearchIcon className="searchIcon" />{" "}
            <input
              placeholder={searchtype ? `Search By ${searchtype}` : "Search By Id"}
              onChange={(e) => {
                searchSurvey(e.target.value);
              }}
            />
          </div>
        )}
        {step && step !== 1 && (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            className="backbtn"
            onClick={() => setStep(1)}
          >
            <ArrowBackIosNewIcon fontSize="small" />
          </IconButton>
        )}
        {editmode && (
          <Link to="/dashboard">
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              className="backbtn"
            >
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
          </Link>
        )}

        <div className="right__headbar">
          <IconButton
            size="small"
            aria-label="close"
            color="lightgray"
            className="headbar__help"
            // onClick={() => window.alert("Help Icon")}
            onClick={() => setOpenHelp(true)}
          >
            <HelpIcon fontSize="medium" />
            &nbsp;Help
          </IconButton>

          {true ? (
            <IconButton
              size="small"
              aria-label="close"
              color="lightgray"
              className="headbar__help"
              onClick={() => window.alert("Notification Icon")}
            >
              <NotificationsIcon fontSize="medium" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              aria-label="close"
              color="lightgray"
              className="headbar__help"
              onClick={() => window.alert("Notification Icon")}
            >
              <NotificationsActiveIcon fontSize="medium" />
            </IconButton>
          )}
          <HtmlTooltip title={ <React.Fragment>
            <Button onClick={() => handleLogout()} color="inherit">Logout</Button>
          </React.Fragment>}>
          <Avatar
            src=".assets/images/logo.png"
          />
      </HtmlTooltip>
         
        </div>
      </div>
      <Modal open={openHelp} onClose={() => setOpenHelp(false)}>
        <Help handleClose={() => setOpenHelp(false)} />
      </Modal>
    </>
  );
};
export default TopBar;
