import React from 'react';
import styled from 'styled-components/macro';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';

export default function Drawer({
  children,
  open,
  onOpen,
  onClose,
  width,
  height,
  color,
}) {
  const useStyles = makeStyles({
    paper: {
      background: color,
    },
  });

  const classes = useStyles();
  return (
    <div>
      <Container>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          style={{ zIndex: '9999' }}
          classes={{ paper: classes.paper }}
        >
          <Wrapper width={width} height={height} color={color}>
            {children}
          </Wrapper>
        </SwipeableDrawer>
      </Container>
    </div>
  );
}

export const Container = styled.div`
  position: relative;
`;
export const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => width || 'inherit'};
  height: ${({ height }) => height || 'inherit'};
  overflow: hidden;
`;
