import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  IconButton,
  Pagination,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArticleIcon from "@mui/icons-material/Article";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorIcon from "@mui/icons-material/Error";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ebe9e9",
    color: "#25282B",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F1F4FF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const GetConversionandStatus=({id})=>{
  const [budgetLeft,setBudgetLeft] = useState(0)
  const [conversion,setConversion] = useState(0)

  useEffect(() =>{
    axios
    .get(`https://socialchat.azurewebsites.net/earn/status/${id}`)
      .then((result) => 
      {
       console.log(result.status === 200 , result?.data , result?.data?.count)
        if(result.status === 200)
        {
          setBudgetLeft((result?.data?.count )*10)
          setConversion((result?.data?.click))
        }        
        })
      .catch((e) => console.log(e));
  },[])
  return <>
  <StyledTableCell align="center">
              {/* {row.budgetleft} */}
              {budgetLeft ? budgetLeft: '---'}
              
            </StyledTableCell>
            <StyledTableCell align="center">
              
              {conversion? conversion : '---'}
            </StyledTableCell>
</>
   
}
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRightIcon />
        ) : (
          <KeyboardArrowLeftIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeftIcon />
        ) : (
          <KeyboardArrowRightIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const CustomTable = ({
  completed,
  total,
  setSelectedId,
  setTotal,
  setHandleModal,
  setHandleCompletedModal,
  tableData,
  setTypeSelected,
  fetching,
  setTableData,
  setHandleMailModal,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [emptyRows,setEmptyRows] = React.useState(0)
  useState(()=>{console.log(Math.max(0, (1 + page) * rowsPerPage - tableData.length) );setEmptyRows(Math.max(0, (1 + page) * rowsPerPage - tableData.length))},[rowsPerPage, setRowsPerPage,tableData])
    

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    axios.defaults.headers["x-auth-token"] = window.localStorage.getItem("jwt");
    console.log(tableData);
  }, []);

  const statusComponent = (status) => {
    switch (status) {
      case "Pending":
        return (
          <h2
            className="action"
            style={{
              color: "#FF6760",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="./assets/images/pending.png"
              alt="pending"
            />
            Pending
          </h2>
        );
      case "Modification":
        return (
          <h2
            className="action"
            style={{
              color: "#185ADB",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="./assets/images/request.png"
              alt="pending"
            />
            Request for <br />
            modification
          </h2>
        );
      case "Active":
        return (
          <h2
            className="action"
            style={{
              color: "#29A915",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="./assets/images/active.png"
              alt="pending"
            />
            Active
          </h2>
        );
      case "Completed":
        return (
          <h2
            className="action"
            style={{
              color: "#336CFB",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="./assets/images/completed.png"
              alt="completed"
            />
            Completed
          </h2>
        );
      case "Rejected":
        return (
          <h2
            className="action"
            style={{
              color: "#FF6760",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src="./assets/images/rejected.png"
              alt="rejected"
            />
            Rejected
          </h2>
        );
    }
  };

  return (
    <>
    <TableContainer
      component={Paper}
      style={{ top: "30px", minHeight: "430px" }}
    >
      <Table
        sx={{ minWidth: 700 , minHeight: "520px"}}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow style={{ height: "56px" }}>
            <StyledTableCell>Promo Id</StyledTableCell>
            <StyledTableCell align="center">Total Budget</StyledTableCell>
            <StyledTableCell align="center">Costmodel</StyledTableCell>
            <StyledTableCell align="center">Budget Left</StyledTableCell>
            <StyledTableCell align="center">Conversion</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>

        {fetching ? (
          <TableBody>
            <StyledTableRow style={{ height: "100%" }}>
              <TableCell align="center" colSpan={7}>
                <h2
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ marginRight: "20px" }} />
                  Loading...
                </h2>
              </TableCell>
            </StyledTableRow>
          </TableBody>
        ) : (
          <>
            {tableData.length > 0 ? (
              <TableBody>
                {(rowsPerPage > 0
                  ? tableData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : tableData
                ).map((row, index) => {
                  
                  {/* .get(`https://socialchat.azurewebsites.net/earn/status/6171214d9b890fd9c30de980`) */}


                  return  <StyledTableRow key={row._id}>
                    <StyledTableCell component="th" scope="row">
                      {row._id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Rs. {row.budget}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.questions ? "Per Response" : "Per Click"}
                    </StyledTableCell>
                    <GetConversionandStatus id = {row?.id}/>
                    <StyledTableCell align="center">
                      {/* {row.status} */}
                      {statusComponent(row.status)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <h2 className="action">
                        {!completed  ? (
                          (row.status === "Modification" || row.status === "Pending") && 
                          <img
                            style={{ marginRight: "20px", cursor: "pointer" }}
                            onClick={() => {
                              console.log("clicked", row._id);
                              setHandleModal(true);
                              setSelectedId(row._id);
                              row?.questions
                                ? setTypeSelected("survey")
                                : setTypeSelected("promo");
                            }}
                            src="./assets/images/editicon.png"
                            alt="editicon"
                          />
                        ) : (
                          row.status === "Completed" && false &&  ( //removing sheets logo that's why used 'false'
                            <img
                              style={{
                                marginRight: "20px",
                                height: "15px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                console.log("clicked", row._id);
                                setHandleMailModal(true);
                                setSelectedId(row._id);
                                row?.questions
                                  ? setTypeSelected("survey")
                                  : setTypeSelected("promo");
                              }}
                              src="./assets/images/sheets-logo.png"
                              alt="editicon"
                            />
                          )
                        )}
                        {completed && (
                          <img
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            onClick={() => {
                              setSelectedId(row._id);
                              setHandleCompletedModal(true);
                              row?.questions
                                ? setTypeSelected("survey")
                                : setTypeSelected("promo");
                            }}
                            src="./assets/images/deleteicon.png"
                            alt="deleteicon"
                          />
                        )}{" "}
                        {!completed ? (
                          <img
                            src="/assets/images/timeclock.png"
                            alt="timeicon"
                          />
                        ) : (
                          <Button
                            onClick={() => {
                              setHandleModal(true);
                              setSelectedId(row._id);
                              row?.questions
                                ? setTypeSelected("survey")
                                : setTypeSelected("promo");
                            }}
                            size="small"
                            variant="contained"
                            color="primary"
                          >
                            FeedBack
                          </Button>
                        )}
                      </h2>
                    </StyledTableCell>
                  </StyledTableRow>
                  
                })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: `${72 * emptyRows}px` }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <StyledTableRow style={{ height: "100%" }}>
                  <TableCell align="center" colSpan={7}>
                    <h2
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ErrorIcon style={{ marginRight: "10px" }} />
                      No Promo Found
                    </h2>
                  </TableCell>
                </StyledTableRow>
              </TableBody>
            )}
          </>
        )}

        
        <TableFooter style={{ width: "100% !important" }}>
        <Divider  style={{position: "absolute !important"}} />
          <TableRow style={{ height: "64px" ,width: "100% !important" }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={7}
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
          <Divider  style={{position: "absolute !important"}} />
        </TableFooter>
      </Table>
    </TableContainer>
    </>
  );
  // return (
  //   <div className="table">
  //     <div
  //       className="heading"
  //       style={completed ? { width: "100%" } : { width: "85%" }}
  //     >
  //       {completed ? (
  //         <h2 style={{ flex: 0.10, textAlign: "left" }}>Promo Id</h2>
  //       ) : (
  //         <h2 style={{ flex: 0.10, textAlign: "left" }}>Promo Id</h2>
  //       )}
  //       {completed && (
  //         <h2 style={{ flex: 0.15, textAlign: "left" }}>
  //           Product/
  //           <br />
  //           Services
  //         </h2>
  //       )}
  //       {completed ? (<>
  //         <h2 style={{ flex: 0.10, textAlign: "left" }}>Total Budget</h2>
  //       <h2 style={{ flex: 0.20, textAlign: "right" }}>Cost Model</h2>
  //       <h2 style={{ flex: 0.15,textAlign: "right" }}>Budget Left</h2>
  //       <h2 style={{ flex: 0.15,textAlign: "right" }}>Conversion</h2>
  //       <h2 style={{ flex: 0.15,textAlign: "center" }}>Status</h2>
  //       <h2 style={{ flex: 0.20, textAlign: "center" }}>FeedBack</h2>
  //       </>):(<>
  //         <h2 style={{ flex: 0.15, textAlign: "left" }}>Total Budget</h2>
  //       <h2 style={{ flex: 0.25, textAlign: "center" }}>Cost Model</h2>
  //       <h2 style={{ flex: 0.15, textAlign: "left" }}>Budget Left</h2>
  //       <h2 style={{ flex: 0.15, textAlign: "left" }}>Conversion</h2>
  //       <h2 style={{ flex: 0.15, textAlign: "left" }}>Status</h2>
  //       </>)}

  //     </div>
  //     <Divider />
  //     {!completed
  //       ? tableData.map((item, index) => (
  //           <RowData
  //             promoid={item.id}
  //             key={index}
  //             editmode
  //             index={index}
  //             totalbudget={item.budget}
  //             setHandleModal={setHandleModal}
  //             setSelectedId={setSelectedId}
  //             costmodel={item.costmodel}
  //             budgetleft={item.budgetleft}
  //             conversion={item.conversion}
  //             status={item.status}
  //           />
  //         ))
  //       : tableData.map((item, index) => (
  //           <RowData
  //             promoid={item._id}
  //             key={index}
  //             completed
  //             setHandleModal={setHandleModal}
  //             setSelectedId={setSelectedId}
  //             index={index}
  //             totalbudget={item.budget}
  //             costmodel={item.costmodel}
  //             budgetleft={item.budgetleft}
  //             conversion={item.conversion}
  //             status={item.status}
  //             deleteExisting={deleteExisting}
  //           />
  //         ))}

  //     <div className="bottomnav">
  //       {/* <div className="nav">
  //       <div className="box"><ChevronLeftIcon/></div>
  //       <div className="box">{1}</div>
  //       <div className="box">{2}</div>
  //       <div className="box">{3}</div>
  //       <div className="box"><ChevronRightIcon/></div>
  //   </div> */}
  //       <Pagination
  //         onChange={(val) => {
  //           handlePagination(val);
  //         }}
  //         count={3}
  //         variant="outlined"
  //         shape="rounded"
  //       />
  //       <button onClick={() => window.location.reload()} className="plusIcon">
  //         +
  //       </button>
  //     </div>
  //   </div>
  // );
};

export default CustomTable;

// const RowData = ({
//   promoid,
//   editmode,
//   totalbudget,
//   costmodel,
//   budgetleft,
//   conversion,
//   deleteExisting,
//   status,
//   setSelectedId,
//   setHandleModal,
//   index,
//   completed,
// }) => {
//   const handle = (id) => {
//     setHandleModal(true);
//     setSelectedId(promoid);
//   };
//   return (
//     <>
//       <div
//         style={
//           index % 2 === 0
//             ? { backgroundColor: "#f1f4ff" }
//             : { backgroundColor: "#ffffff" }
//         }
//         className="rowcontainer"
//       >
//         <div
//           className="rowdata"
//           style={completed ? { height: "54px" } : { height: "46px" }}
//         >
//           {completed ? (
//             <>
//               <h2 style={{ flex: 0.1, textAlign: "left" }}>
//                 {promoid.slice(0, 7)}
//               </h2>
//               <h2 style={{ flex: 0.15, textAlign: "left" }}>SmartPhone</h2>
//               <h2 style={{ flex: 0.1, textAlign: "left" }}>
//                 {totalbudget}1234
//               </h2>
//               <h2 style={{ flex: 0.2, textAlign: "center" }}>
//                 {costmodel}Cost Model
//               </h2>
//               <h2 style={{ flex: 0.15 }}>{budgetleft}5000</h2>
//               <h2 style={{ flex: 0.15 }}>{conversion}1203</h2>
//               <h2 style={{ flex: 0.15 }}>{status}</h2>
//             </>
//           ) : (
//             <>
//               <h2 style={{ flex: 0.15, textAlign: "left" }}>
//                 {promoid.slice(0, 7)}
//               </h2>
//               <h2 style={{ flex: 0.15, textAlign: "left" }}>{totalbudget}</h2>
//               <h2 style={{ flex: 0.25, textAlign: "left" }}>{costmodel}</h2>
//               <h2 style={{ flex: 0.15, ttextAlign: "center" }}>{budgetleft}</h2>
//               <h2 style={{ flex: 0.15, textAlign: "center" }}>{conversion}</h2>
//               <h2 style={{ flex: 0.15, textAlign: "center" }}>{status}</h2>
//             </>
//           )}
//         </div>
//         <h2 className="action">
//           {editmode && (
//             <img
//               onClick={() => {
//                 console.log("clicked", promoid);
//                 setHandleModal(true);
//                 setSelectedId(promoid);
//               }}
//               src="./assets/images/editicon.png"
//               alt="editicon"
//             />
//           )}
//           {completed && (
//             <DeleteIcon deleteExisting={deleteExisting} promoid={promoid} />
//           )}{" "}
//           {!completed ? (
//             <TimeIcon />
//           ) : (
//             <Button
//               onClick={() => {
//                 setHandleModal(true);
//                 setSelectedId(promoid);
//               }}
//               size="small"
//               variant="contained"
//               color="primary"
//             >
//               FeedBack
//             </Button>
//           )}
//         </h2>
//       </div>
//       <Divider />
//     </>
//   );
// };

// var tabledata = [
// {
//   promoid: "1",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "2",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "3",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "4",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "5",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "6",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "7",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "9",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// {
//   promoid: "10",
//   totalbudget: "1000",
//   costmodel: "20",
//   budgetleft: "200",
//   conversion: "450",
//   status: "active",
// },
// ];
