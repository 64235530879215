import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '../../helpers';
import { Help } from '../../pages/_index';
import './footer.scss';

const Footer = () => {
  const [openHelp, setOpenHelp] = useState(false);
  return (
    <>
      <div className="footer">
        <div className="f-01">
          <p onClick={() => setOpenHelp(true)}>Help & Documentation </p>
          <p> Intellectual Property </p>
         <Link to="/disclaimers"> <p>Disclaimer</p></Link>
          <p>About Us </p>
          <p>Pricing </p>
        </div>
        <div className="f-02"> 
        <a href="mailto:contact@etark.in">

        <p>Contact us</p></a>
        <Link to="/privacy">
          <p> Privacy </p>
          </Link>
          <Link to="/terms_and_conditions">
          <p> T&C </p>
          </Link>
          <Link to="/refund-and-cancellation-policy">
            <p> Refund and Cancellation Policy </p>
          </Link>
        </div>
        <div className="f-03">
        <Link to="/social-privacy">
            <p> Social Privacy </p>
          </Link>
        </div>
        <div className="f-03">
          <p>@2021 Social</p>
        </div>
      </div>
      <Modal open={openHelp} onClose={() => setOpenHelp(false)}>
        <Help handleClose={() => setOpenHelp(false)} />
      </Modal>
    </>
  );
};

export default Footer;
