import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import {
  Dashboard,
  Reset,
  Signin,
  Signup,
  EditPromo,
} from "./components/_index";
import CreatePage from "./components/Dashboard/pages/CreatePage.js";
import FormPage from "./components/Dashboard/pages/FormPage.jsx";

import PrivateRoute from "./helpers/PrivateRoute";
import {
  LandingPage,
  Refund,
  Terms,
  Privacy,
  SocialPrivacy,
  Disclaimers,
} from "./pages/_index";
import EditPage from "./components/Dashboard/pages/EditPage";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import AdminSignin from "./components/Auth/AdminSigin";
import AdminRoute from "./helpers/AdminRoute";

function App() {
  let token = localStorage.getItem("jwt");

  useEffect(() => {
    if (token) {
      <Redirect to="/signin" />;
    }
  }, [token]);

  return (
    <>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route
          exact
          path="/refund-and-cancellation-policy"
          component={Refund}
        />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/social-privacy" component={SocialPrivacy} />
        <Route exact path="/terms_and_conditions" component={Terms} />
        <Route exact path="/disclaimers" component={Disclaimers} />

        <Route exact path="/signin" component={Signin} />
        <Route exact path="/admin/signin" component={AdminSignin} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/resetpassword" component={Reset} />

        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        {/* <PrivateRoute exact path="/dashboard/promo" component={Promo} />
        <PrivateRoute exact path="/dashboard/form" component={Promo} /> */}

        {/* <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} /> */}
        {/* <Route exact path="/form/:id" component={EditPromo} /> */}
        <PrivateRoute exact path="/promo/:id">
          <EditPage routeStep="3" />
        </PrivateRoute>
        <PrivateRoute exact path="/form/:id">
          <EditPage routeStep="2" />
        </PrivateRoute>
      </Switch>
    </>
  );
}

export default App;
