import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import "./LeftTabBar.scss";
import { makeStyles } from "@material-ui/core/styles";
import { TabsBtn1, TabsBtn2 } from "./TabsBtn";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from "@material-ui/icons/MenuOutlined";
import { Drawer } from "../../../helpers";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    height: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "4px 0px 16px rgba(16, 30, 115, 0.08)",
    minWidth: "280px",
    zIndex: 1,
    padding: 24,
  },
  logo: {
    width: 70,
    marginTop: 50,
    marginRight: 7,
  },
  logoText: {
    color: "#185adb",
    marginLeft: 10,
    marginTop: 49,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    letterSpacing: "0.02em",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "-19px",
      left: "-8px",
      display: "block",
      height: 60,
      width: 1.5,
      backgroundColor: "#336fcb",
    },
  },
  promos: {
    marginTop: 40,
    fontSize: 14,
    fontWeight: 400,
    color: "#A0A4A8",
  },
  TabsHead: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
}));

const LeftTabBar = ({ choice, setChoice, isActive,email }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const handleClick = (choice) => {
   
    if(choice === 7)
    {
      history.push({
        pathname: '/admin/dashboard',
        state: 
        {
          choice,
        }
      });
      setChoice(choice);
      return;
    }
    if(email)
    {
      history.push({
      pathname: '/admin/dashboard',
      state: 
      {
        choice,
        email
      }
    });
    setChoice(choice);
    }
  }
  const toggleNav = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };


  return (
    <>
      <div className="toggleNav">
        <Button
          onClick={() => {
            toggleNav();
          }}
        >
          <MenuOutlinedIcon />
        </Button>
      </div>

      <div className="root">
        <Drawer open={open} onClose={onClose} width="15rem" color="#fff">
          <Link to="/" style={{ textDecoration: "none" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                background: "#ffffff",
              }}
            >
              <img
                alt="logo"
                src="/assets/images/social.png"
                className={classes.logo}
              />
              <div className={classes.logoText}>For Business</div>
            </div>
          </Link>
          <div className={classes.promos} style={{ marginLeft: "1.5rem" }}>
            Promo Account
          </div>
          <div className={classes.TabsHead} style={{ marginLeft: "1.5rem" }}>
            {choice === 7 ? (
              <TabsBtn2 text="Promo Accounts" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Promo Accounts"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(7);
                }}
              />
            )}
            {choice === 1 ? (
              <TabsBtn2 text="Pending for Verification" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Pending for Verification"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(1);
                }}
              />
            )}
            {choice === 2 ? (
              <TabsBtn2 text="Request for Modification" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Request for Modification"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(2);
                }}
              />
            )}
            {choice === 3 ? (
              <TabsBtn2 text="Active Promos" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Active Promos"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(3);
                }}
              />
            )}
            {choice === 4 ? (
              <TabsBtn2 text="Completed Promos" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Completed Promos"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(4);
                }}
              />
            )}
            {choice === 5 ? (
              <TabsBtn2 text="Rejected Promos" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Rejected Promos"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(5);
                }}
              />
            )}
            {choice === 6 ? (
              <TabsBtn2 text="Payment Pending" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Payment Pending"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(6);
                }}
              />
            )}
          </div>
        </Drawer>

        <Link to="/" style={{ textDecoration: "none" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <img
              alt="logo"
              src="/assets/images/social.png"
              className={classes.logo}
            />
            <div className={classes.logoText}>For Business</div>
          </div>
        </Link>
        <div className={classes.TabsHead}>
        {choice === 7 ? (
              <TabsBtn2 text="Promo Accounts" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Promo Accounts"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(7);
                  console.log("Verification")
                }}
              />
            )}
            {choice === 1 ? (
              <TabsBtn2 text="Pending for Verification" type="promo__account" />
            ) : (
              <TabsBtn1
                text="Pending for Verification"
                type="promo__account"
                onClickFunction={() => {
                  handleClick(1);
                  console.log("Verification")
                }}
              />
            )}
          {choice === 2 ? (
            <TabsBtn2 text="Request for Modification" type="promo__account" />
          ) : (
            <TabsBtn1
              text="Request for Modification"
              type="promo__account"
              onClickFunction={() => {
                handleClick(2);
              }}
            />
          )}
          {choice === 3 ? (
            <TabsBtn2 text="Active Promos" type="promo__account" />
          ) : (
            <TabsBtn1
              text="Active Promos"
              type="promo__account"
              onClickFunction={() => {
                handleClick(3);
              }}
            />
          )}
          {choice === 4 ? (
            <TabsBtn2 text="Completed Promos" type="promo__account" />
          ) : (
            <TabsBtn1
              text="Completed Promos"
              type="promo__account"
              onClickFunction={() => {
                handleClick(4);
              }}
            />
          )}
          {choice === 5 ? (
            <TabsBtn2 text="Rejected Promos" type="promo__account" />
          ) : (
            <TabsBtn1
              text="Rejected Promos"
              type="promo__account"
              onClickFunction={() => {
                handleClick(5);
              }}
            />
          )}
          {choice === 6 ? (
            <TabsBtn2 text="Payment Pending" type="promo__account" />
          ) : (
            <TabsBtn1
              text="Payment Pending"
              type="promo__account"
              onClickFunction={() => {
                handleClick(6);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LeftTabBar;

/*
1 -> pending verification
2 -> request modification
3 -> active
4 -> completed
5 -> rejected
6 -> payment pending
*/
