import React, { useEffect, useState } from "react";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Resizer from "react-image-file-resizer";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  Snackbar,
  TextField,
} from "@material-ui/core";
import ExpectedPreview from "../ExpectedPreview/ExpectedPreview";
import InAppPreview from "../InAppPreview/InAppPreview";
import axios from "axios";
import { useLocation } from "react-router";
import { Modal } from "../../../helpers";
import { ErrorContainer, ModalContainer } from "../Dashboard";
import Close from "@material-ui/icons/Close";
import { useParams } from "react-router";
import { GuideLines } from "../../../pages/_index";
import { TopBar } from "../TopBar";
import { FormPage } from "../_index";
import { PromoPage } from "../_index";
import LeftTabBar from "../LeftTabBar/LeftTabBar";

const useStyles = makeStyles((theme) => ({
  titleInput: {
    width: "15rem",
    marginBottom: "2.5rem",
    "& .MuiFormHelperText-contained": {
      marginLeft: 2,
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    "& .MuiInputBase-input": {
      height: ".6rem !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
    },
  },
  desInput: {
    width: "80%",
    minWidth: "16rem",
    marginBottom: 20,
    "& .MuiFormHelperText-contained": {
      marginLeft: 2,
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      height: "2.1876em !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
  },
  linkInput: {
    width: "20rem",
    marginBottom: "2.2rem",
    "& p": {
      color: "red",
      fontWeight: "600",
      fontSize: "14px",
    },
    "& .MuiInputBase-input": {
      height: ".6em !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "16rem",
    },
  },
  budgetInput: {
    "& .MuiInputBase-root": {
      color: "#336fcb",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#336fcb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#336fcb",
      },
      "&:hover fieldset": {
        border: "1px solid #336fcb",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #336fcb",
      },
    },
    "& .MuiInputBase-input": {
      height: ".6rem !important",
    },
  },
}));

const api = process.env.REACT_APP_API;
const paytmapi = process.env.REACT_APP_PAYTM;
const mid = process.env.REACT_APP_MID;

const CreatePage = ({ routeStep }) => {
  const [error, setError] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false || "");
  const [loading, setLoading] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [completedModal, setCompletedModal] = useState(false);
  const [guidlines, setGuidlines] = useState(false);
  const [selectedValue, setSelectedValue] = useState("promo");
  const [step, setStep] = useState(1);
  
  
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    console.log(selectedValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const stepFunction = () => {
    if (step === 1)
      return (
        <>
          <div className="menu">
            <h2>Whats your goal?</h2>
            <div className="option1">
              <div className="leftimg">
                <img style={{width:35,height:30}} src="./assets/images/promo_icon.png" alt="promo icon" />
                {/* <PhotoLibraryIcon /> */}
              </div>
              <div className="middle">
                <h2>Promo</h2>
                <p>
                  Get assured organic clicks on your app/website link(this is
                  relevant for any sales or promotion requirement)
                </p>
              </div>
              <div className="radiobutton">
                <Radio
                  checked={selectedValue === "promo"}
                  onChange={handleRadioChange}
                  value="promo"
                  name="radio-buttons"
                  color="primary"
                  inputProps={{ "aria-label": "Promo" }}
                />
                {/* <PhotoLibraryIcon /> */}
              </div>
            </div>
            <div className="option1">
              <div className="leftimg">
                <img style={{width:40,height:40}} src="./assets/images/survey_icon.png" alt="survey icon" />
                {/* <PhotoLibraryIcon /> */}
              </div>
              <div className="middle">
                <h2>Primary research</h2>
                <p>
                  Get responses straight from the users and generate insights to
                  create better products or services
                </p>
              </div>
              <div className="radiobutton">
                <Radio
                  checked={selectedValue === "form"}
                  onChange={handleRadioChange}
                  value="form"
                  color="primary"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "Form" }}
                />
                {/* <PhotoLibraryIcon /> */}
              </div>
            </div>
          </div>
        </>
      );
    else if (step == 2)
      return (
        <>
          <FormPage  />
        </>
      );
    else if (step == 3)
      return (
        <>
          <PromoPage />
        </>
      );
    else if (step == 4) return <>Error Please check your id</>;
  };

  useEffect(()=>{
    axios.defaults.headers["x-auth-token"] = window.localStorage.getItem("jwt");
  },[])

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleClose}
        message={msg}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <TopBar setStep={setStep} step={step} search={false} />

      <h1>Control Dashboard</h1>
      
      {stepFunction()}
      {step === 1 && (
        <>
          <div className="conitinue">
            <span className="publish--button">
              <Button
                // disabled={!checked}
                onClick={() => {
                  // handlePublish();
                  selectedValue === "promo" && setStep(3);
                  selectedValue === "form" && setStep(2);
                }}
              >
                Continue
              </Button>
            </span>
          </div>
        </>
      )}
      <Modal
        open={completedModal}
        onClose={() => {
          setCompletedModal(false);
        }}
      >
        {errMsg && (
          <>
            <ErrorContainer>
              <Close
                onClick={() => {
                  console.log(paymentId);
                  setPaymentId(null);
                  setError("");
                  window.location.search = "";
                }}
              />
              {errMsg}
            </ErrorContainer>
          </>
        ) }
      </Modal>
      <Modal open={guidlines} handleClose={() => setGuidlines(false)}>
        <GuideLines handleClose={() => setGuidlines(false)} />
      </Modal>
    </>
  );
};

export default CreatePage;
