import React from 'react';
import { Navbar } from '../components/_index';
import { Content, SubTitle, Title } from './_styles';

const Refund = () => {
  return (
    <>
      <div>
        <Navbar />
        <Title>Refund and Cancellation Policy</Title>
        <Content>
          <p style={{ fontStyle: 'italic' }}>
            Any Capitalized terms used but not defined herein shall have the
            meaning assigned to them under the Terms of Use which govern your
            use of our website https://social.etark.in/ (the “Website”). The
            Website is also referred to as the “Platform”.
          </p>
          <br />
          <SubTitle pl="none">(i) User Cancellation</SubTitle>
          <p>
            As a general rule, a User shall not be entitled to cancel A Promo
            once published.
          </p>
          <br />
          <SubTitle pl="none">(ii) Cancellation policy for Social</SubTitle>
          <p>
            Any Promo which violates Social Promotion Guidelines shall be given
            one opportunity to make required changes through the Request for
            Modification mode during which the User needs to make the required
            changes and resubmit the Promo without making any additional payment
            to us, however if still the Promo is founding violating the Social
            Promotion Guidelines, your Promo would get rejected by us. Social
            reserves the right to reject Promos due to reasons beyond the Social
            Promotion Guidelines on account of extraneous circumstances arising
            beyond the control of Social like Govt. requests or Security
            reasons.
          </p>
          <br />
          <SubTitle pl="none">(iii) Refunds or Compensation</SubTitle>
          <p>
            In the unlikely event of the Promo being rejected by us on account
            of Social Promotion Guidelines violation, the equivalent amount set
            in the Ad Budget for the particular Promo under conflict shall be
            refunded back to the account holder within 7- 10 weeks from the date
            of Promo rejection by us. In the unlikely event of your Promo not
            achieving the minimum number of clicks equivalent to the expected
            number of clicks as shown to you while setting up the Promo, you
            shall be refunded back an amount equivalent to the remaining clicks
            which is equal to the difference between the expected number of
            clicks shown to you and the number of clicks you have achieved at
            that particular point. Please note that a time period of 60 days
            from the date you have made your Promo live is required in order to
            estimate if your promo has achieved the estimated number of clicks.
          </p>
          <br />
          <p>Our decision on refunds shall be final and binding.</p>
        </Content>
      </div>
    </>
  );
};

export default Refund;
