import React, { useState } from 'react';
import './Navbar.scss';
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from '@material-ui/core';
import { Drawer, Modal } from '../../helpers';
import { Help } from '../../pages/_index';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [drawer, setDrawer] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const token = localStorage.getItem('jwt');

  return (
    <>
      <MenuIcon className="menu-icon" onClick={() => setDrawer(true)} />
      <Drawer open={drawer} onClose={() => setDrawer(false)} width="15rem">
        <div className="navbar">
          <div className="logo-area">
            <Link to="/">
              <img
                className="social-logo"
                src="/assets/images/social.png"
                alt=""
              />
            </Link>
            <span />
          </div>
          <div className="nav-links">
            <p
              onClick={() => {
                setOpenHelp(true);
                setDrawer(false);
              }}
            >
              HOW TO USE
            </p>
            <a href="mailto:socialtheapp.marketing@gmail.com">CONTACT US</a>
            {token ? (
              <p
                onClick={() => {
                  localStorage.clear();
                  localStorage.removeItem('jwt');
                  window.location.reload();
                  console.log('logout');
                }}
              >
                Logout
              </p>
            ) : (
              <Link to="/signin">
                <p>LOGIN</p>
              </Link>
            )}
            <Link to="/dashboard">
              <Button>GET STARTED</Button>
            </Link>
          </div>
        </div>
      </Drawer>
      <div className="navbar">
        <div className="dn">
          <div className="logo-area">
            <Link to="/">
              <img
                className="social-logo"
                src="/assets/images/social.png"
                alt=""
              />
            </Link>
          </div>
          <div className="nav-links">
            <p onClick={() => setOpenHelp(true)}>HOW TO USE</p>
            <a href="mailto:socialtheapp.marketing@gmail.com">CONTACT US</a>
            {token ? (
              <p
                onClick={() => {
                  localStorage.clear();
                  localStorage.removeItem('jwt');
                  window.location.reload();
                  console.log('logout');
                }}
              >
                Logout
              </p>
            ) : (
              <Link to="/signin">
                <p>LOGIN</p>
              </Link>
            )}
            <Link to="/dashboard">
              <Button>GET STARTED</Button>
            </Link>
          </div>
        </div>
      </div>
      <Modal open={openHelp} onClose={() => setOpenHelp(false)}>
        <Help handleClose={() => setOpenHelp(false)} />
      </Modal>
    </>
  );
};

export default Navbar;
